import React, { useCallback, useMemo } from 'react';

import sumBy from 'lodash/sumBy';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useReconciliationCenterContext } from '@src/hooks/contexts/reconciliation_center_context';
import { useCreateTransactionMatch } from '@src/hooks/queries/transaction_matches';
import { useBulkVerifyTransactionServiceDocuments } from '@src/hooks/queries/transaction_service_documents';
import { ICombinedServiceDocument } from '@src/types/combined_service_documents';
import { ITransactionMatchItemData } from '@src/types/transaction_match_items';
import { ICreateTransactionMatchData } from '@src/types/transaction_matches';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { Button } from '@src/components/ui_v2/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import { ICSDCollection, ITSDCollection } from '../hooks';
import { useAddAdjustmentModal } from './modal';
import { IAddAdjustmentValues } from './schema';

interface IMatchDocumentsAddAdjustmentEnabledProps {
  csdCollection: ICSDCollection,
  tsdCollection: ITSDCollection,
  onFinishVerifying: () => void;
  onCancelVerifying: () => void;
  onStartVerifying: () => void;
}

const transactionInfo = (
  selectedCSDRecords: ICombinedServiceDocument[],
  csdSelectedAmount: number,
) => {
  const firstDocument = selectedCSDRecords[0];
  const vendorId = firstDocument.vendor?.id;
  const chartOfAccountId = firstDocument.chartOfAccount?.id;
  const serviceDocumentSplits = firstDocument.serviceDocumentSplits;

  const params = {
    transactionType: csdSelectedAmount > 0
      ? window.Docyt.Common.Constants.TRANSACTION_TYPES.EXPENSE_TYPE
      : window.Docyt.Common.Constants.TRANSACTION_TYPES.REFUND_TYPE,
    vendorId:                  vendorId || null,
    chartOfAccountId:          chartOfAccountId || null,
    userServiceDocumentSplits: serviceDocumentSplits || [],
    userVendorId:              vendorId || null,
    userChartOfAccountId:      chartOfAccountId || null,
    userTransactionType:       csdSelectedAmount > 0
      ? window.Docyt.Common.Constants.TRANSACTION_TYPES.EXPENSE_TYPE
      : window.Docyt.Common.Constants.TRANSACTION_TYPES.REFUND_TYPE,
  };
  if (serviceDocumentSplits?.length) {
    const receivableBusinessSplit = serviceDocumentSplits.find(
      (i) => i.receivableBusinessAccountId && i.receivableBusinessId,
    );
    if (receivableBusinessSplit) {
      params.userServiceDocumentSplits = [];
      params.chartOfAccountId = receivableBusinessSplit.chartOfAccountId;
    }
  }
  return params;
};

const MatchDocumentsAddAdjustmentEnabled = ({
  csdCollection,
  tsdCollection,
  onFinishVerifying,
  onCancelVerifying,
  onStartVerifying,
}: IMatchDocumentsAddAdjustmentEnabledProps) => {
  const business = useBusinessContext();
  const reconciliationCenter = useReconciliationCenterContext();

  const { selectedRecords: selectedCSDRecords } = csdCollection;
  const { selectedRecords: selectedTSDRecords } = tsdCollection;

  const csdSelectedAmount = useMemo(() => {
    return sumBy(selectedCSDRecords, (r) => parseFloat(r.amountVerified));
  }, [selectedCSDRecords]);

  const tsdSelectedAmount = useMemo(() => {
    return sumBy(selectedTSDRecords, (r) => parseFloat(r.amount));
  }, [selectedTSDRecords]);

  const adjustmentAmount = useMemo(() => {
    return Math.abs(parseFloat(tsdSelectedAmount.toFixed(2)))
      - Math.abs(parseFloat(csdSelectedAmount.toFixed(2)));
  }, [csdSelectedAmount, tsdSelectedAmount]);

  const createTransactionMatch = useCreateTransactionMatch();

  const { mutate } = createTransactionMatch;
  const handleVerified = useCallback((adjustmentData?: IAddAdjustmentValues) => {
    const fromItems: ITransactionMatchItemData[] = selectedCSDRecords.map((item) => {
      if (item.documentType === 'Invoice') {
        return { id: item.serviceDocumentId, type: 'ServiceDocument' };
      }

      return { id: item.serviceDocumentId, type: 'ReceiptServiceDocument' };
    });

    const toItems: ITransactionMatchItemData[] = selectedTSDRecords.map((item) => {
      return { id: item.id, type: 'TransactionServiceDocument' };
    });

    const qboPref = window.Docyt.Common.Constants.QBO_PREFERENCES.DOCUMENTS;
    const data: ICreateTransactionMatchData = {
      reconciliationCenterId: reconciliationCenter.id,
      qboPreference:          qboPref,
      transactionMatch:       {
        transactionMatchItems: [...fromItems, ...toItems],
      },
      adjustmentEntry: adjustmentData ? {
        amount:        adjustmentAmount,
        accountSplits: adjustmentData.categorySplits,
      } : undefined,
    };

    mutate(
      { data },
      {
        onSettled: onFinishVerifying,
      },
    );
  }, [
    adjustmentAmount,
    mutate,
    reconciliationCenter.id,
    selectedCSDRecords,
    selectedTSDRecords,
    onFinishVerifying,
  ]);

  const bulkVerifyTransactions = useBulkVerifyTransactionServiceDocuments();
  const bulkVerifyModal = useBulkActionModal({
    closeOnSuccess: true,
    mutation:       bulkVerifyTransactions,
    onDone:         onFinishVerifying,
    onCancel:       onCancelVerifying,
  });

  const { runMutation: runBulkVerify } = bulkVerifyModal;
  const handleModalDone = useCallback((adjustmentData?: IAddAdjustmentValues) => {
    const unverifiedTransactions = selectedTSDRecords.filter((r) => r.state === 'unverified');

    if (unverifiedTransactions.length > 0) {
      runBulkVerify(unverifiedTransactions.map((t) => ({
        transactionId: t.id,
        data:          transactionInfo(selectedCSDRecords, csdSelectedAmount),
      }))).then((result) => {
        if (result.errors.length > 0) return;

        handleVerified(adjustmentData);
      });
    } else {
      handleVerified(adjustmentData);
    }
  }, [
    csdSelectedAmount,
    handleVerified,
    runBulkVerify,
    selectedCSDRecords,
    selectedTSDRecords,
  ]);

  const addAdjustmentModal = useAddAdjustmentModal({
    onDone:   handleModalDone,
    onCancel: onCancelVerifying,
  });

  const handleClick = useCallback(() => {
    onStartVerifying();
    if (!business.canEditTransactionMatch) {
      toastr.error(window.Docyt.Common.Constants.Messages.TRANSACTION_MATCH_PERMISSION, 'Something went wrong');
      return;
    }

    if (adjustmentAmount !== 0) {
      addAdjustmentModal.open();
    } else {
      handleModalDone();
    }
  }, [
    business.canEditTransactionMatch,
    adjustmentAmount,
    addAdjustmentModal,
    handleModalDone,
    onStartVerifying,
  ]);

  return (
    <>
      <addAdjustmentModal.Component
        adjustmentAmount={ String(adjustmentAmount) }
        { ...addAdjustmentModal.props }
      />
      <bulkVerifyModal.Component
        { ...bulkVerifyModal.props }
        itemsTitle="Transactions"
        title="Verify Transactions"
      />
      <MutationStatus mutation={ createTransactionMatch } />
      <Button
        size="compact"
        variant="primary"
        onClick={ handleClick }
      >
        Match
      </Button>
    </>
  );
};

export default MatchDocumentsAddAdjustmentEnabled;
