import React from 'react';

import { IAccessibleManagementGroup } from '@src/types/management_groups';

import { AddCircleIcon } from '@src/components/utils/icomoon';

import { TUnifiedDropdownOption } from './types';

const managementGroupOptions:TUnifiedDropdownOption = {
  label:        'Management Groups',
  value:        'management-groups',
  displayLabel: 'Management Groups',
  type:         'navigate' as const,
  icon:         null,
};

export const prepareManagementGroupValue = (id: number) => `management-group-${id}`;
export const prepareManagementGroupBillPayValue = (id: number) => `management-group-bill-pay-${id}`;
export const prepareManagementGroupJournalEntriesValue = (id: number) => `management-group-journal-entries-${id}`;
export const prepareManagementGroupInterAccountTransfersValue = (id: number) => (
  `management-group-inter-account-transfers-${id}`
);
export const prepareManagementGroupFinancialConnectionValue = (id: number) => (
  `management-group-financial-connection-${id}`
);
export const prepareBusinessValue = (id: number) => `business-${id}`;

const portfolioOptions = (managementGroups: IAccessibleManagementGroup[]) => [
  { ...managementGroupOptions,
    subMenuItems: [
      {
        label:        'New Group',
        value:        'add-management-group',
        displayLabel: '',
        id:           undefined,
        type:         'action-add' as const,
        icon:         <AddCircleIcon fontSize={ 16 } />,
      },
      ...managementGroups.map((group) => ({
        label:        group.name,
        value:        prepareManagementGroupValue(group.id),
        id:           group.id,
        displayLabel: group.name,
        type:         'navigate' as const,
        icon:         null,
        subMenuItems: [
          {
            label:        'Bill Pay',
            value:        prepareManagementGroupBillPayValue(group.id),
            type:         'navigate' as const,
            id:           group.id,
            displayLabel: group.name,
          },
          {
            label:        'Journal Entries',
            value:        prepareManagementGroupJournalEntriesValue(group.id),
            type:         'navigate' as const,
            id:           group.id,
            displayLabel: group.name,
          },
          {
            label:        'Inter-account Transfers',
            value:        prepareManagementGroupInterAccountTransfersValue(group.id),
            type:         'navigate' as const,
            id:           group.id,
            displayLabel: group.name,
          },
          {
            label:        'Financial Connection',
            value:        prepareManagementGroupFinancialConnectionValue(group.id),
            type:         'navigate' as const,
            id:           group.id,
            displayLabel: group.name,
            icon:         null,
          },
        ],
      })),
    ] },
];

export default portfolioOptions;
