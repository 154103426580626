import React, { useCallback, useState } from 'react';

import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';
import { currencyFormater } from '@src/utils/currency';

import Table from '@src/components/ui_v2/table';
import { NewTabLinkIcon } from '@src/components/utils/img_icons';

import { IColumnNamesAndWidths } from './types';

import styles from './styles.module.scss';

interface IThirdDrillDownRowProps {
  lineItemValue: IReportServiceQBOLineItemDetails,
  columnNamesAndWidths: IColumnNamesAndWidths;
}

const ThirdDrillDownRow = ({
  lineItemValue,
  columnNamesAndWidths,
}: IThirdDrillDownRowProps): JSX.Element => {
  const [showDocumentLink, setShowDocumentLink] = useState(false);

  const handleRowHover = useCallback((event: any) => {
    if (event.type === 'mouseover') {
      setShowDocumentLink(true);
    } else {
      setShowDocumentLink(false);
    }
  }, [setShowDocumentLink]);

  const isTotal = lineItemValue.transactionType === window.Docyt.Common.Constants.ADVANCED_REPORT_LINE_ITEM_TYPE.TOTAL;
  const isBeginningBalance =
  lineItemValue.transactionType === window.Docyt.Common.Constants.ADVANCED_REPORT_LINE_ITEM_TYPE.BEGINNING_BALANCE;

  const handleRowClick = useCallback(() => {
    if (lineItemValue.link) {
      window.open(lineItemValue.link, '_blank');
    }
  }, [lineItemValue.link]);

  if (isTotal || isBeginningBalance) {
    return (
      <Table.Row key={ lineItemValue.id }>
        <Table.TextCell hideValueTooltip className="text-center" widthPercent={ columnNamesAndWidths.date }>
          {isTotal ? 'Total' : 'Beginning Balance'}
        </Table.TextCell>
        <Table.TextCell widthPercent={ columnNamesAndWidths.transaction_type } />
        <Table.TextCell widthPercent={ columnNamesAndWidths.num } />
        <Table.TextCell widthPercent={ columnNamesAndWidths.name } />
        <Table.TextCell className="font-bold last-column text-center" widthPercent={ columnNamesAndWidths.amount }>
          <span>{currencyFormater(lineItemValue.amount)}</span>
        </Table.TextCell>
        <Table.TextCell widthPercent={ columnNamesAndWidths.memo } />
        <Table.TextCell widthPercent={ columnNamesAndWidths.split } />
      </Table.Row>
    );
  }

  return (
    <Table.Row
      key={ lineItemValue.id }
      className={ styles['hoverable-row'] }
      onClick={ handleRowClick }
      onMouseLeave={ handleRowHover }
      onMouseOver={ handleRowHover }
    >
      <Table.TextCell className="text-center" widthPercent={ columnNamesAndWidths.date }>
        {lineItemValue.transactionDate}
      </Table.TextCell>
      <Table.TextCell className="text-center" widthPercent={ columnNamesAndWidths.transaction_type }>
        {lineItemValue.transactionType}
      </Table.TextCell>
      <Table.TextCell className="text-center" widthPercent={ columnNamesAndWidths.num }>
        {lineItemValue.transactionNumber}
      </Table.TextCell>
      <Table.TextCell className="text-center" widthPercent={ columnNamesAndWidths.name }>
        {lineItemValue.vendor}
      </Table.TextCell>
      <Table.TextCell className="text-center" widthPercent={ columnNamesAndWidths.amount }>
        {currencyFormater(lineItemValue.amount)}
      </Table.TextCell>
      <Table.TextCell className="text-left" widthPercent={ columnNamesAndWidths.memo }>
        {lineItemValue.memo}
      </Table.TextCell>
      <Table.TextCell className="text-left" tooltip={ lineItemValue.split } widthPercent={ columnNamesAndWidths.split }>
        <div>
          <span>{lineItemValue.split}</span>
          {lineItemValue.link && showDocumentLink
          && (
          <span className="pull-right m-r-5 m-l-2" style={ { zIndex: 10 } }>
            <NewTabLinkIcon className="m-l-0" fontSize={ 14 } />
          </span>
          )}
        </div>
      </Table.TextCell>
    </Table.Row>
  );
};

export default ThirdDrillDownRow;
