import React, { useCallback, useEffect, useState } from 'react';

import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useGetPortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import { useLocalStorage } from '@src/hooks/storage';
import {
  chartOfAccountsPath,
  multiEntityReportsPath,
  portfolioDashboardPath,
  portfolioDashboardsWidgetPath,
} from '@src/routes';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';
import { IPortfolio } from '@src/types/portfolio';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import AddNewPortfolio from './add_new_portfolio';
import ServiceItem from '../header/module_header/module_header_item';
import ModuleHeaderMenu from '../header/module_header/module_header_menu';
import { useSelectedPortfolio } from '../portfolio_dashboard/hooks';
import SearchInput from '../ui_v2/search_dropdown/search_input';

import styles from './styles.module.scss';

interface IServiceMenuProps {
  launchDarklyFeature?: TLaunchDarklyFeature,
}

const PortfolioDropDownList = ({
  launchDarklyFeature,
}: IServiceMenuProps) => {
  const portfolioResp = useGetPortfolio();
  const storedPortfolio = useLocalStorage('selectedPortfolio', {});
  const { selectedPortfolioId } = useSelectedPortfolio();

  const featureFlag = window.Docyt.Common.Constants.CHART_OF_ACCOUNTS_FLAG;
  const { data: isCOAEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    featureFlag,
  );
  const { data: isPortfolioFeatureEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.email,
    window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION,
  );

  const [portfolioList, setPortfolioList] = useState<IPortfolio[]>([]);
  const [filterPortfolioList, setFilterPortfolioList] = useState<IPortfolio[]>([]);

  useEffect(() => {
    if (portfolioResp?.data?.portfolios && portfolioResp.data.portfolios.length > 0) {
      setPortfolioList(portfolioResp?.data?.portfolios);
      setFilterPortfolioList(portfolioResp?.data?.portfolios);
    } else {
      setPortfolioList([]);
      setFilterPortfolioList([]);
    }
  }, [portfolioResp.data]);

  const getSelectedPortfolio = () => {
    if (portfolioList.length > 0) {
      const tempSelectedPortfolio = portfolioList.filter(
        (item) => item.id === Number(selectedPortfolioId),
      );
      if (tempSelectedPortfolio.length) {
        return tempSelectedPortfolio[0].name.toUpperCase();
      }
    }
    if (isPortfolioFeatureEnabled) {
      return 'Select Portfolio';
    }
    return window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE.toUpperCase();
  };

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>, portfolio: IPortfolio) => {
    event.preventDefault();
    storedPortfolio.setValue(portfolio);
    backboneNavigateToUrl(portfolioDashboardsWidgetPath(portfolio.id));
  };

  const handleSearch = useCallback((searchKey: string) => {
    const filteredPortfolioList = portfolioList.filter(
      (item) => item.name.toLowerCase().includes(searchKey.toLowerCase()),
    );
    setFilterPortfolioList(filteredPortfolioList);
  }, [setFilterPortfolioList, portfolioList]);

  return (
    <div data-global-feature-toggle={ launchDarklyFeature }>
      <ModuleHeaderMenu
        activePlaceholder={ !selectedPortfolioId }
        className="font-16 font-bold"
        listClassName="portfolio-dropdown-list"
        showSelect={ false }
        title={ getSelectedPortfolio() }
      >
        {!isPortfolioFeatureEnabled ? (
          <>
            <ServiceItem
              serviceUrl={ portfolioDashboardPath() }
              title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.KPI_DASHBOARD }
            />
            <ServiceItem
              serviceUrl={ multiEntityReportsPath(Number(selectedPortfolioId)) }
              title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS }
            />
            {isCOAEnabled && (
              <ServiceItem
                serviceUrl={ chartOfAccountsPath(Number(selectedPortfolioId)) }
                title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.CHART_OF_ACCOUNTS }
              />
            )}
          </>
        ) : (
          <>
            <div className={ styles['portfolio-search-input-container'] }>
              <SearchInput
                placeholder="Search Portfolio"
                onChange={ handleSearch }
              />
            </div>
            {filterPortfolioList.map((portfolio) => {
              return (
                <ServiceItem
                  key={ portfolio.name }
                  serviceUrl=""
                  title={ portfolio.name }
                  onClickHandler={ (event) => handleOnClick(event, portfolio) }
                />
              );
            })}
          </>
        )}
        <AddNewPortfolio />
      </ModuleHeaderMenu>
    </div>
  );
};

export default PortfolioDropDownList;
