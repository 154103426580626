import React, { FocusEvent, useCallback } from 'react';

import { Control, useController, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useSaveDocumentFieldValue, useUpdateVendor } from '@src/hooks/queries/document_fields';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IBusinessVendor } from '@src/types/business_vendors';
import { IDocument } from '@src/types/documents';
import { MOMENT_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import VendorField from '@src/components/common_v2/form_fields/vendor_field';
import Form from '@src/components/ui_v2/form';

import CategoryField from './category_field';
import PaymentAddressField from './payment_adress_field';
import PeriodField from './period_fields';
import { getFormFieldLabel, isFieldRequired } from '../utils';

import styles from './styles.module.scss';

interface IForm {
  control: Control<IAccountsPayableServiceDocument>;
  register: UseFormRegister<IAccountsPayableServiceDocument>;
  watch: UseFormWatch<IAccountsPayableServiceDocument>;
  setValue: UseFormSetValue<IAccountsPayableServiceDocument>;
}

interface IDocumentFieldsProps {
  document: IDocument;
  serviceDocument: IAccountsPayableServiceDocument;
  form: IForm;
}

const DocumentFields = ({ document, serviceDocument, form }: IDocumentFieldsProps) => {
  const {
    control,
    register,
    watch,
    setValue,
  } = form;

  const { mutateAsync: saveDocumentFieldValue } = useSaveDocumentFieldValue();
  const { mutateAsync: updateVendor } = useUpdateVendor();

  const saveFieldValue = useCallback((value: string, localStandardDocumentFieldId: number) => {
    // Do not save if the field is required and the value is empty
    if (isFieldRequired(localStandardDocumentFieldId) && !value) {
      return;
    }

    saveDocumentFieldValue({
      documentFieldValue: {
        documentId: document.id,
        id:         null,
        inputValue: value,
        localStandardDocumentFieldId,
      },
    }).then(() => {
      window.Docyt.vent.trigger('ap_service:document:refresh');
    }).catch((error) => {
      toastr.error(error.message, 'Error');
    });
  }, [document, saveDocumentFieldValue]);

  const onChangeVendor = useCallback((vendor?: IBusinessVendor | null) => {
    if (!vendor) return;
    saveFieldValue(vendor.name, window.configData.account_payable_invoice_vendor_field_id);
    updateVendor({
      document: {
        id:       document.id,
        vendorId: vendor.id,
      },
    }).catch((error) => {
      toastr.error(error.message, 'Error');
    });
  }, [document, saveFieldValue, updateVendor]);

  const onBlurAmountField = useCallback((e: FocusEvent<HTMLInputElement>) => {
    // Remove the $ sign
    const value = e.target.value.slice(1);
    // remove , from the value
    const formattedValue = value.replace(/,/g, '');
    saveFieldValue(formattedValue, window.configData.account_payable_invoice_amount_field_id);
  }, [saveFieldValue]);

  const onChangeInvoiceDate = useCallback((value: string) => {
    setValue('invoiceDate', value);
    // convert to mm/dd/yyyy format
    const formattedValue = formatDate(value, MOMENT_DATE_FORMAT);
    saveFieldValue(formattedValue, window.configData.account_payable_invoice_date_field_id);
  }, [setValue, saveFieldValue]);

  const onChangeInvoiceDueDate = useCallback((value: string) => {
    setValue('invoiceDueDate', value);
    const formattedValue = formatDate(value, MOMENT_DATE_FORMAT);
    saveFieldValue(formattedValue, window.configData.account_payable_invoice_due_date_field_id);
  }, [setValue, saveFieldValue]);

  const amountField = useController({
    control,
    name: 'invoiceAmount',
  });

  const invoiceDateValue = watch('invoiceDate');
  const invoiceDueDateValue = watch('invoiceDueDate');
  const invoiceAmountValue = watch('invoiceAmount');

  return (
    <div className={ styles['document-fields'] }>
      <VendorField
        businessId={ document.businesses[0].id }
        className={ styles['document-field'] }
        label={
          getFormFieldLabel('Vendor', isFieldRequired(window.configData.account_payable_invoice_vendor_field_id))
        }
        value={ serviceDocument.vendor as IBusinessVendor }
        onChange={ onChangeVendor }
      />
      <Form.AmountField
        hideClear
        className={ styles['document-field'] }
        label={
          getFormFieldLabel('Amount', isFieldRequired(window.configData.account_payable_invoice_amount_field_id))
        }
        { ...amountField.field }
        onBlur={ onBlurAmountField }
      />
      <Form.DateField
        className={ styles['document-field'] }
        label={
          getFormFieldLabel('Invoice Date', isFieldRequired(window.configData.account_payable_invoice_date_field_id))
        }
        value={ invoiceDateValue }
        onChange={ onChangeInvoiceDate }
      />
      <Form.DateField
        className={ styles['document-field'] }
        label={
          getFormFieldLabel('Due Date', isFieldRequired(window.configData.account_payable_invoice_due_date_field_id))
        }
        value={ invoiceDueDateValue }
        onChange={ onChangeInvoiceDueDate }
      />
      <Form.TextField
        hideClear
        className={ styles['document-field'] }
        label={
          getFormFieldLabel('Invoice No', isFieldRequired(window.configData.account_payable_invoice_number_field_id))
        }
        { ...register('invoiceNumber') }
        onBlur={ (e) => saveFieldValue(e.target.value, window.configData.account_payable_invoice_number_field_id) }
      />
      <Form.TextField
        hideClear
        className={ styles['document-field'] }
        label={
          getFormFieldLabel('Account No', isFieldRequired(window.configData.account_payable_account_number_field_id))
        }
        { ...register('accountNumber') }
        onBlur={ (e) => saveFieldValue(e.target.value, window.configData.account_payable_account_number_field_id) }
      />
      <CategoryField
        document={ document }
        invoiceAmount={ invoiceAmountValue }
        serviceDocument={ serviceDocument }
      />
      <PeriodField />
      <PaymentAddressField address={ document.serviceDocumentAddress } />
    </div>
  );
};

export default React.memo(DocumentFields);
