import React from 'react';

import isNil from 'lodash/isNil';

import { useLDBusinessFeatureQuery, useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID } from '@src/types/common';

import GlobalNavigationHeaderActions from '../global_navigation_header_actions';
import Actions from '../module_header_actions';

interface IModuleHeaderPageProps {
  businessId: TID,
  currentModulePath?: string,
  focusToMyClient: boolean,
  hasVaultServiceRole: boolean,
}

const ModuleHeaderPage = ({
  businessId,
  currentModulePath,
  focusToMyClient,
  hasVaultServiceRole,
}: IModuleHeaderPageProps) => {
  const { data: globalNavigationBusinessEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
  );
  const { data: globalNavigationUserEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
  );

  const isGlobalNavigationEnabled: boolean = !!((globalNavigationUserEnabled || globalNavigationBusinessEnabled));

  return (
    <div>
      {(isGlobalNavigationEnabled && isNil(focusToMyClient)) ? (
        <GlobalNavigationHeaderActions
          businessId={ businessId }
          currentModulePath={ currentModulePath }
          focusToMyClient={ focusToMyClient }
          isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
        />
      ) : (
        <Actions
          businessId={ businessId }
          currentModulePath={ currentModulePath }
          focusToMyClient={ focusToMyClient }
          hasVaultServiceRole={ hasVaultServiceRole }
        />
      )}
    </div>
  );
};

export default ModuleHeaderPage;
