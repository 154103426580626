import React, { forwardRef, useRef, RefObject } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useInfiniteRegionScroll } from '@src/hooks/scroll';

import LocalQueryStatus from '@src/components/utils/local_query_status';

interface IInfiniteScrollTableProps extends React.ComponentPropsWithoutRef<'table'> {
  wrapperClassName?: string,
  infiniteQuery: UseInfiniteQueryResult<any, Error>,
}

const InfiniteRegionScrollTable = forwardRef<HTMLDivElement, IInfiniteScrollTableProps>(({
  children,
  infiniteQuery,
  wrapperClassName,
  ...tableProps
}: IInfiniteScrollTableProps, ref) => {
  const localRef = useRef<HTMLDivElement>(null);
  const wrapperRef = ref as RefObject<HTMLDivElement> || localRef;

  useInfiniteRegionScroll({
    elementRef: wrapperRef,
    query:      infiniteQuery,
  });

  return (
    <div
      ref={ wrapperRef }
      className={ wrapperClassName }
    >
      <table { ...tableProps }>
        { children }
      </table>
      <LocalQueryStatus noSpinner={ !infiniteQuery?.isFetchingNextPage } query={ infiniteQuery } />
    </div>
  );
});

InfiniteRegionScrollTable.displayName = 'InfiniteRegionScrollTable';

export default React.memo(InfiniteRegionScrollTable);
