export const normalizeFilterData = (filterData: Record<string, any>): Record<string, any[]> => {
  return Object.fromEntries(
    Object.entries(filterData).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value];
      }
      if (typeof value === 'object' && value !== null) {
        return [key, Object.values(value)];
      }
      if (value !== undefined) {
        return [key, [value]];
      }
      return [key, []];
    }),
  );
};
