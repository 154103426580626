import React, { useCallback, useMemo, useState } from 'react';

import { useGetTaskServiceDocumentById } from '@src/hooks/queries/task_service_documents';
import { taskNotificationsStatusPath } from '@src/routes';
import { ITaskServiceDocument } from '@src/types/task_service_documents';
import { parseSearchParams } from '@src/utils/filter/url_helpers';

import TaskDetailsHeader from '@src/components/header/task_detail/task_detail_header';
import Spinner from '@src/components/ui/spinner';

import DetailsSection from './details_section';
import { paginationDirections, statusToUrlMap, TASK_TABS } from './helper/constants';
import { normalizeFilterData } from './helper/functional';
import TasksLogs from './tasks_logs';

import styles from '../styles.module.scss';

const TaskDetails = ({ taskId }: { taskId: string }) => {
  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const filterParams = new URLSearchParams(
    decodeURIComponent(String(searchParams.get('filter'))),
  );
  const sortingParams = new URLSearchParams(
    decodeURIComponent(String(searchParams.get('sorting'))),
  );
  const filterData = parseSearchParams(filterParams);
  const sortingData = parseSearchParams(sortingParams);
  const [id, setId] = useState<string>(taskId);
  const { data, isLoading } =
  useGetTaskServiceDocumentById(
    { params: {
      filter: normalizeFilterData({ ...filterData }), ...sortingData,
    },
    taskId: id },
  );
  const navigateToTask = useCallback((TId: string) => {
    const newUrl = `/task_management/${TId}?${searchParams.toString()}`;
    Backbone.history.navigate(newUrl);
  }, [searchParams]);

  const handlePaginationClick = useCallback((val) => {
    if (val === paginationDirections.LEFT && data?.prevId) {
      setId(data.prevId);
      navigateToTask(data.prevId);
    } else if (data?.nextId && val === paginationDirections.RIGHT) {
      setId(data.nextId);
      navigateToTask(data.nextId);
    }
  }, [data, navigateToTask]);

  const handleBack = () => {
    const statusArray = normalizeFilterData({ ...filterData })?.status;
    const url = statusToUrlMap[statusArray?.[0]] || taskNotificationsStatusPath(TASK_TABS.OPEN.toLocaleLowerCase());
    Backbone.history.navigate(url, { trigger: true });
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <TaskDetailsHeader
        copyUrl={ window.location.href }
        data={ data }
        taskId={ id }
        onClick={ handlePaginationClick }
        onGoBack={ handleBack }
      />
      <div className={ styles['task-container'] }>
        <div className={ styles['task-content'] }>
          <DetailsSection
            taskServiceDocument={ data as ITaskServiceDocument }
            onGoBack={ handleBack }
          />
          <TasksLogs taskId={ id } />
        </div>
      </div>
    </>
  );
};

export default TaskDetails;
