import React from 'react';

import { Routes, Route } from 'react-router-dom';

import { availableStatuses, TASK_TABS } from './components/helper/constants';
import TaskManagementContainer from './components/task_management_container';
import CreateTaskDrawer from './tasks_list/create_task_drawer';

const TaskManagementRouter = (): JSX.Element | null => {
  return (
    <Routes>
      <Route path="/task_management/tasks">
        <Route
          element={ (
            <TaskManagementContainer
              headerRegionChild={ <CreateTaskDrawer /> }
              headerTitle="Open Tasks"
              status={ [availableStatuses.OPEN] }
              tab={ TASK_TABS.OPEN }
            />
        ) }
          path="open"
        />
        <Route
          element={ (
            <TaskManagementContainer
              headerTitle="Assigned Tasks"
              status={ [availableStatuses.PENDING, availableStatuses.IN_PROGRESS] }
              tab={ TASK_TABS.ASSIGNED }
            />
) }
          path="assigned"
        />
        <Route
          element={ (
            <TaskManagementContainer
              headerTitle="Blocked Tasks"
              status={ [availableStatuses.BLOCKED] }
              tab={ TASK_TABS.BLOCKED }
            />
) }
          path="blocked"
        />
        <Route
          element={ (
            <TaskManagementContainer
              headerTitle="Closed Tasks"
              status={ [availableStatuses.CLOSED] }
              tab={ TASK_TABS.CLOSED }
            />
) }
          path="closed"
        />
      </Route>
    </Routes>
  );
};

export default React.memo(TaskManagementRouter);
