import React, { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useGetBusinessQuery } from '@src/hooks/queries/businesses';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';

import { useEditManagementGroupModal } from '@src/components/management_groups/modals/edit_management_group_modal';

import BusinessView from './business_view';
import ClientView from './client_view';
import { UnifiedHeaderDropdown } from './unified_header_dropdown';

interface IDetailsProps {
  focusToMyClient: boolean,
}

const Details = ({
  focusToMyClient,
}: IDetailsProps) => {
  const isUnifiedHeaderDropDownFeatureFlagEnabled = useUserFeatureFlag(
    window.Docyt.Common.Constants.UNIFIED_HEADER_DROP_DOWN,
  );
  // Setup the management group create modal
  const createManagementGroupModal = useEditManagementGroupModal();
  const location = useLocation();
  let currentBusinessId: string | undefined;

  const isClientView = window.Docyt.currentAdvisor.isBookgptAccountantUser();

  if (focusToMyClient) {
    currentBusinessId = location.pathname.split('/')[3] === 'clients' ? location.pathname.split('/')[4] : location.pathname.split('/')[2];
  } else {
    currentBusinessId = location.pathname.split('/')[1] === 'businesses' ? location.pathname.split('/')[2] : undefined;
  }

  const businessQuery = useGetBusinessQuery(Number(currentBusinessId));
  const currentBusiness = useMemo(() => {
    return businessQuery.data;
  }, [businessQuery.data]);

  const renderContent = () => {
    if (typeof isUnifiedHeaderDropDownFeatureFlagEnabled === 'undefined') {
      return null;
    }

    if (isUnifiedHeaderDropDownFeatureFlagEnabled) {
      return (
        <UnifiedHeaderDropdown openCreateManagementGroupModal={ createManagementGroupModal.open } />
      );
    }

    if (focusToMyClient || isClientView) {
      return <ClientView currentClientBusiness={ currentBusiness } />;
    }

    return <BusinessView currentBusiness={ currentBusiness } />;
  };

  return (
    <div>
      <createManagementGroupModal.Component
        { ...createManagementGroupModal.props }
      />
      {renderContent()}
    </div>
  );
};

export default Details;
