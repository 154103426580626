import React, { useCallback, useEffect, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetSystemCategories } from '@src/hooks/queries/transaction_service_document';
import { useUpdateNoDocumentRequired, useUpdateTransactionServiceDocumentDoNotLearn } from '@src/hooks/queries/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { underscoreKeys } from '@src/utils/transform_keys';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import MutationStatus from '@src/components/utils/mutation_status';

import { useTSDCollection } from './hooks';
import List from './list';
import Sidebar from './sidebar';

import styles from './styles.module.scss';

const AllTransactions = ({ title, type }: { title?: string, type?: string }) => {
  const business = useBusinessContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [rowData, setRowData] = useState<ITransactionServiceDocument | null>(null);

  const tsdCollection = useTSDCollection({ businessId: business.id, excluded: false, type });

  const totalTransactions = tsdCollection.query.data?.pages[0].meta.totalCount;
  const totalAmount = tsdCollection.query.data?.pages[0].meta.totalAmount || 0;
  const formattedTotalAmount = totalAmount > 0 ? `$${Math.abs(totalAmount)}` : `-$${Math.abs(totalAmount)}`;

  const updateNoDocumentRequired = useUpdateNoDocumentRequired();
  const { mutateAsync: updateNoDocumentRequiredMutate } = updateNoDocumentRequired;
  const systemCategoriesQuery = useGetSystemCategories(business.id);

  const updateRowData = useCallback((currentRowData: ITransactionServiceDocument | null) => {
    if (currentRowData) {
      const pages = tsdCollection.query.data?.pages || [];
      const updatedData = pages.flatMap((page) => page.collection).find(
        (item: ITransactionServiceDocument) => item.id === currentRowData.id,
      );

      setRowData(updatedData || currentRowData);
    }
  }, [tsdCollection.query.data]);

  const handleRowClick = async (data: ITransactionServiceDocument) => {
    updateRowData(data);
    setIsSidebarOpen(true);
  };

  const handleRefreshData = useCallback(async () => {
    await tsdCollection.query.refetch();
  }, [tsdCollection.query]);

  useEffect(() => {
    const handleAddDocument = async (document: ITransactionServiceDocument) => {
      // @ts-ignore
      const businessModel = new window.Docyt.Entities.Business(business);
      // @ts-ignore
      const rcService = new window.Docyt.Entities.ReconciliationCenter();

      try {
        const [rcData] = await Promise.all([
          rcService.fetch({
            url: `/api/v1/reconciliation_centers/by_business_id?business_id=${business.id}`,
          }),
        ]);

        rcService.set('reconciliation_center', rcData.reconciliation_center);

        rcService.get = (key: string) => rcService.attributes[key];

        // @ts-ignore
        const addDocumentMixin = window.Docyt.Common.Mixins.AddDocument;
        // @ts-ignore
        addDocumentMixin.onAddDocument(rcService, businessModel, document, true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in handleAddDocument:', error);
      }

      // @ts-ignore
      window.Docyt.vent.once('add:related:document:done', handleRefreshData);
    };

    const handleNoDocumentNeeded = (document: ITransactionServiceDocument) => {
      const data = {
        id:          document.id,
        document_id: document.documentId,
        document:    underscoreKeys(document),
      } as Record<string, unknown>;

      const model = {
        ...data,
        get(key: string) { return data[key]; },
        set(key: string, value: unknown) { data[key] = value; },
        toJSON() { return data; },
        trigger() {},
      };

      // @ts-ignore
      // eslint-disable-next-line max-len
      const modalView = new window.Docyt.AdvisorHomeApp.Businesses.Show.Services.Show.ReconciliationCenter.AddDocumentNoteModal({
        model,
      });

      modalView.on('document:note:added', async () => {
        // @ts-ignore
        window.Docyt.vent.trigger('hide:spinner');
        await updateNoDocumentRequiredMutate({
          id:                 document.id,
          noDocumentRequired: true,
        });

        // @ts-ignore
        window.Docyt.vent.trigger('transaction:match:list:added');
        // @ts-ignore
        window.Docyt.vent.trigger('transaction:document:note:added');

        handleRefreshData();
      });

      // @ts-ignore
      window.Docyt.secondModalRegion.show(modalView);
    };

    // @ts-ignore
    window.Docyt.vent.on('related:document:add', handleAddDocument);
    // @ts-ignore
    window.Docyt.vent.on('transaction:document:note:add', handleNoDocumentNeeded);

    return () => {
      // @ts-ignore
      window.Docyt.vent.off('related:document:add', handleAddDocument);
      // @ts-ignore
      window.Docyt.vent.off('add:related:document:done', handleRefreshData);
      // @ts-ignore
      window.Docyt.vent.off('transaction:document:note:add', handleNoDocumentNeeded);
    };
  }, [business, handleRefreshData, updateNoDocumentRequiredMutate]);

  useEffect(() => {
    updateRowData(rowData);
  }, [tsdCollection.query.data, rowData, updateRowData]);

  return (
    <>
      <MutationStatus mutation={ updateNoDocumentRequired } />
      <div className={ styles.container }>
        <DetailsRegion>
          <DetailsRegion.Header title={ title || 'All Transactions' } />
          <div className={ styles['tables-container'] }>
            <List
              collection={ tsdCollection }
              payrollBCOAId={
                systemCategoriesQuery.query.data?.payrollBusinessChartOfAccount
                  ?.chartOfAccountId || null
              }
              systemCategories={ systemCategoriesQuery.query.data || null }
              type={ type }
              onRowClick={ handleRowClick }
            />
          </div>
          <div className={ styles['total-transactions'] }>
            Total Transactions:
            <span className={ styles['total-transactions-value'] }>{ totalTransactions }</span>
            <span>｜</span>
            Total Amount:
            <span className={ styles['total-transactions-value'] }>{ formattedTotalAmount }</span>
          </div>
        </DetailsRegion>
        { isSidebarOpen && rowData && (
          <Sidebar
            key={ rowData?.id }
            businessId={ business.id }
            isLoading={ false }
            reloadSystemCategories={ systemCategoriesQuery.query.refetch }
            rowData={ rowData }
            setRowData={ setRowData }
            systemCategories={ systemCategoriesQuery.query.data || null }
            onClose={ () => setIsSidebarOpen(false) }
            onRefreshData={ handleRefreshData }
          />
        )}
      </div>
    </>
  );
};

export default React.memo(AllTransactions);
