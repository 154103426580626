import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';

import { useGetLineItemDetails } from '@src/hooks/queries/report_service/report_items';
import { IReport } from '@src/types/report_service/report';
import { IItemAccount } from '@src/types/report_service/report_item';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';

import Table from '@src/components/ui_v2/table';

import ThirdDrillDownBody from './body';
import Breadcrumb from './breadcrumb';
import ThirdDrillDownHeader from './header';
import { IColumnNamesAndWidths } from './types';
import FullWidthModal from '../full_width_modal/index';

import styles from './styles.module.scss';

interface thirdDrillDownTableProps {
    report: IReport,
    item: IItemAccount,
    showThirdDrillDown: boolean,
    setShowThirdDrillDown: React.Dispatch<React.SetStateAction<boolean>>;
    topRowColumnsCount: number;
}

const ThirdDrillDownTable = ({
  report,
  item,
  showThirdDrillDown,
  setShowThirdDrillDown,
  topRowColumnsCount,
}: thirdDrillDownTableProps) => {
  const [showThirdDrillDownModal, setShowThirdDrillDownModal] = useState(false);

  const columnNamesAndWidths:IColumnNamesAndWidths = {
    date:             8,
    transaction_type: 10,
    num:              12,
    name:             12,
    amount:           14,
    memo:             22,
    split:            22,
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(currentPage + 1);
  const [lineItemValues, setLineItemValues] = useState<IReportServiceQBOLineItemDetails[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const observerTarget = useRef<HTMLTableRowElement>(null);
  const PAGE_SIZE = 20;

  const hasTotalItem = useCallback((data: IReportServiceQBOLineItemDetails[]) => {
    return data.some((lineItemValue: IReportServiceQBOLineItemDetails) => lineItemValue.transactionType === 'total');
  }, []);

  const { data, isFetching, error } = useGetLineItemDetails(
    report,
    item,
    currentPage,
    lastPage,
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isFetching && !error) {
          setCurrentPage((prevPage) => prevPage + 1);
          setLastPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 0.5 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [data, hasMore, isFetching, setCurrentPage, setLastPage, error]);

  useEffect(() => {
    if (data?.length && data?.length > 0 && !isFetching && !error) {
      const nextPagePresent = data?.length === PAGE_SIZE
        && !hasTotalItem(data);
      setHasMore(nextPagePresent);
      if (nextPagePresent) {
        setLastPage((prevPage) => prevPage + 1);
      } else {
        setLastPage(0);
      }
    }
    if (error) {
      setHasMore(false);
      setLastPage(0);
    }
  }, [data, hasTotalItem, setHasMore, setLastPage, isFetching, error]);

  useMemo(() => {
    if (data?.length && data?.length > 0 && !isFetching && !error) {
      setLineItemValues((prev) => Array.from(new Set([...prev, ...data])));
    }
  }, [data, isFetching, setLineItemValues, error]);

  return (
    <Table.Cell colSpan={ topRowColumnsCount + 1 }>
      <FullWidthModal
        className={ styles['expanded-details'] }
        isOpen={ showThirdDrillDownModal }
        onClose={ () => setShowThirdDrillDownModal(false) }
      >
        { showThirdDrillDownModal && <Breadcrumb item={ item } onClick={ () => setShowThirdDrillDownModal(false) } />}
        <Table isRegionScroll className={ styles['third-drill-down-table'] } wrapperClassName="table-region-container">
          <ThirdDrillDownHeader
            columnNamesAndWidths={ columnNamesAndWidths }
            handleCollapseClick={ () => setShowThirdDrillDown(!showThirdDrillDown) }
            lineItemValuesLoaded={ lineItemValues.length !== 0 && currentPage >= 1 }
            setShowThirdDrillDownModal={ setShowThirdDrillDownModal }
            showThirdDrillDownModal={ showThirdDrillDownModal }
          />
          <ThirdDrillDownBody
            columnNamesAndWidths={ columnNamesAndWidths }
            hasMore={ hasMore }
            isLoading={ isFetching && !error }
            item={ item }
            lineItemValues={ lineItemValues }
            refObserver={ observerTarget }
          />
        </Table>
      </FullWidthModal>
    </Table.Cell>
  );
};

export default React.memo(ThirdDrillDownTable);
