import React from 'react';

import VendorInput from '@src/components/common_v2/inputs/vendor_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';

interface IVendorFieldProps extends
  React.ComponentPropsWithoutRef<typeof VendorInput>,
  IWrapperProps {
}

const VendorField = ({
  ...props
}: IVendorFieldProps): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper
      { ...wrapperProps }
    >
      <VendorInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(VendorField);
