import React, { SetStateAction, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { GroupBase, MultiValue, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import {
  TIconOption,
} from '@src/components/ui_v2/inputs';

import FormatOptionLabel from './option_label';
import TaskAssigneeLabelItem from './task_assignee_label_item';
import { AsyncPaginateReactSelectInput } from './task_react_select_input';
import { ITaskIconOption } from '../types';

import styles from './styles.module.scss';

interface ITaskAssigneeSelectProps {
  handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, { page: number }>;
  isLoading:boolean;
  toggleElement?:string | React.ReactNode;
  selectedItem?:ITaskIconOption;
  hasBorder?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  className?:string;
  selectedOptions?:ITaskIconOption[];
  allOptions?:ITaskIconOption[];
  onSelectAllChecked?:(checked:boolean)=>void;
  onSingleChecked?:(checked:boolean, option : ITaskIconOption)=>void;
  onChange?:(newValue: ITaskIconOption | TIconOption | null | undefined) => void;
  headerText?:string;
  inputValue?:string;
  onInputChange?:(val: string, { action }:{action:string})=>void;
  hideIcon?:boolean;
  noOptionMessage?:string;
  getDropdownState?:(val:boolean)=>void;
}

const Menu: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div
      { ...props }
      className={ styles['dropdown-container'] }
    />
  );
};

const Blanket: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => (
  <div
    { ...props }
    className={ styles.blanket }
  />
);

const Dropdown: React.FC<{
  children: React.ReactNode;
  isOpen: boolean;
  target: React.ReactNode;
  onClose: () => void;
}> = ({ children, isOpen, target, onClose }) => (
  <div className={ styles['dropdown-wrapper'] }>
    {target}
    {isOpen && (
    <>
      <Menu>{children}</Menu>
      <Blanket onClick={ onClose } />
    </>
    )}
  </div>
);

const TaskAssigneeSelect: React.FC<ITaskAssigneeSelectProps> = ({ handleSource, hasBorder = false,
  selectedItem, isLoading, toggleElement, className = '', onChange, selectedOptions, isMulti = false,
  onSingleChecked, onSelectAllChecked, allOptions, headerText, disabled = false, hideIcon, inputValue, onInputChange,
  noOptionMessage, getDropdownState }
  :ITaskAssigneeSelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<ITaskIconOption | TIconOption | undefined>(undefined);

  useEffect(() => {
    if (selectedItem?.value) {
      setValue(selectedItem);
    }
  }, [selectedItem]);
  const toggleOpen = useCallback(() => {
    if (!disabled) setIsOpen((prevIsOpen) => !prevIsOpen);
    if (getDropdownState) getDropdownState(!isOpen);
  }, [disabled, getDropdownState, isOpen]);

  const onSelectChange = useCallback((
    newValue:SingleValue<TIconOption | TIconOption | null> |
      MultiValue<TIconOption | TIconOption | null>,
  ) => {
    if (!isMulti) {
      toggleOpen();
      setValue(newValue as SetStateAction<TIconOption | ITaskIconOption | undefined>);
      if (onChange) onChange(newValue as TIconOption | ITaskIconOption | null | undefined);
    }
  }, [isMulti, onChange, toggleOpen]);
  const formatOptionLabel = useCallback((data) => {
    return (
      <div className={ classNames(styles['multi-select-option'], {
        [styles.selected]: selectedOptions?.some((item) => item.value === data.value),
      }) }
      >
        <FormatOptionLabel
          data={ { ...data, checked: selectedOptions?.find((item) => item.value === data.value) ?? false } }
          hideIcon={ hideIcon }
          isAllSelected={ Number(selectedOptions?.length) >= Number(allOptions?.length) }
          isMulti={ isMulti }
          onSelectAllChecked={ onSelectAllChecked }
          onSingleChecked={ onSingleChecked }
        />
      </div>
    );
  }, [selectedOptions, hideIcon, allOptions, isMulti, onSelectAllChecked, onSingleChecked]);

  const borderClassName = classNames(styles['profile-card'], styles['with-border'], {
    [styles.disabled]: disabled && hasBorder,
  }, className);

  return (
    <Dropdown
      isOpen={ isOpen }
      target={ (
        toggleElement ? (
          <div role="button" tabIndex={ 0 } onClick={ toggleOpen }>
            {toggleElement}
          </div>
        ) : (
          <TaskAssigneeLabelItem
            borderClassName={ borderClassName }
            disabled={ disabled }
            hasBorder={ hasBorder }
            toggleOpen={ toggleOpen }
            value={ value as ITaskIconOption }
          />
        )
      ) }
      onClose={ toggleOpen }
    >
      <>
        {headerText && <div className={ styles['dropdown-title'] }>{headerText}</div>}
        <AsyncPaginateReactSelectInput
          blurInputOnSelect
          defaultOptions
          isSearchable
          menuIsOpen
          additional={ {
            page: 1,
          } }
          debounceTimeout={ 300 }
          formatOptionLabel={ formatOptionLabel }
          inputValue={ inputValue }
          isLoading={ isLoading }
          isMulti={ isMulti }
          loadOptions={ handleSource }
          noOptionsMessage={ () => noOptionMessage ?? 'No users found' }
          onChange={ onSelectChange }
          onInputChange={ onInputChange }
        />
      </>
    </Dropdown>
  );
};
export default TaskAssigneeSelect;
