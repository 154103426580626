import React, { useCallback, useMemo, useRef, useState } from 'react';

import { flatten, omit } from 'lodash';
import { FileRejection } from 'react-dropzone';
import { UseInfiniteQueryResult } from 'react-query';

import configData from '@config/app_config.json';
import toastr from '@lib/toastr';
import { Section } from '@src/constants/sections';
import { useAccountsPayableServiceContext } from '@src/hooks/contexts/accounts_payable_service_context';
import {
  useGetAccountsPayableServiceDocumentsWithPossibleMatches,
} from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useGetAccountsPayableServiceSummary } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { makeBulkRequest } from '@src/hooks/queries/bulk_mutations';
import { useDestroyDocument, useUploadDocument } from '@src/hooks/queries/documents';
import { useSorting } from '@src/hooks/url_params';
import {
  getAccountsPayableServiceDocument,
  IGetAccountsPayableServiceDocumentsResponse,
} from '@src/requests/accounts_payable/accounts_payable_service_documents';
import {
  IAccountsPayableServiceDocument,
  IAccountsPayableServiceDocumentsFilter,
  IAccountsPayableServiceDocumentsQueryFilter,
  TAccountsPayableServiceDocumentsSortColumn,
  IFileStatusInfo,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID, TSection } from '@src/types/common';
import { IDocument } from '@src/types/documents';
import { IServiceSummary } from '@src/types/service_summary';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { amountFilterToQuery, dateFilterToQuery } from '@src/utils/filter';

import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';
import { TItemsSelectionState } from '@src/components/utils_v2/items_selector/types';

import { acceptedMimeTypes, maxFileUploadCount } from './utils';

interface IInvoiceQueueCollectionParams {
  businessId: TID,
  type: string,
}

export interface IInvoiceQueueCollection {
  query: UseInfiniteQueryResult<IGetAccountsPayableServiceDocumentsResponse, Error>,
  records: IAccountsPayableServiceDocument[],
  section: TSection,
  sorting: ISorting<TAccountsPayableServiceDocumentsSortColumn>,
  selectedRecords: IAccountsPayableServiceDocument[],
  totalAmount: string,
  summaryInfo?: IServiceSummary,
  getSelectionState: () => TItemsSelectionState,
  markAll: (checked: boolean) => void,
}

const defaultSorting: ISortingParams<TAccountsPayableServiceDocumentsSortColumn> = {};

const filterToQuery = (
  filterData: IAccountsPayableServiceDocumentsFilter | undefined,
  type: string,
): IAccountsPayableServiceDocumentsQueryFilter | undefined => {
  const query = omit(filterData || { queueType: type }, ['amount', 'invoice_date']);
  query.queueType = type;

  return {
    ...query,
    ...(dateFilterToQuery(filterData?.invoice_date, 'invoice_date') || {}),
    ...(amountFilterToQuery(filterData?.amount, 'invoice_amount', 'value') || {}),
  };
};

export const useInvoiceQueueCollection = ({
  businessId,
  type,
}: IInvoiceQueueCollectionParams): IInvoiceQueueCollection => {
  const service = useAccountsPayableServiceContext();
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.AccountsPayableService,
    };
  }, [businessId]);
  const filterData = useFilterData(section);

  const filterQuery = useMemo(() => {
    return filterToQuery(filterData, type);
  }, [filterData, type]);

  const sorting = useSorting<TAccountsPayableServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const { data: summaryInfo } = useGetAccountsPayableServiceSummary(businessId, 'queued');

  const query = useGetAccountsPayableServiceDocumentsWithPossibleMatches({
    serviceId: service.id,
    filters:   filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const totalAmount = query?.data?.pages[0]?.meta.totalAmount || '0';
  const { selected: selectedIds, markAll, getSelectionState } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    selectedRecords,
    sorting,
    markAll,
    getSelectionState,
    totalAmount,
    summaryInfo,
  };
};

export interface IFileUpload {
  businessId: TID;
  onUploadDone: () => void;
  setDocumentUploadCount: React.Dispatch<React.SetStateAction<number>>;
  onSingleDocumentUpload: (serviceDocument: IAccountsPayableServiceDocument) => void;
  resetStateAfterError: () => void;
}

const isValidFileFormat = (file: File): boolean => {
  return Object.values(acceptedMimeTypes)
    .flat()
    .includes(file.type);
};

export const useFileUpload = ({
  businessId,
  onUploadDone,
  setDocumentUploadCount,
  onSingleDocumentUpload,
  resetStateAfterError,
}: IFileUpload) => {
  const [fileStatuses, setFileStatuses] = useState<IFileStatusInfo[]>([]);

  const isStoppedUploadRef = useRef(false);

  const uploadDocument = useUploadDocument();
  const deleteDocument = useDestroyDocument();

  const handleFileUploadError = useCallback(() => {
    toastr.error(`You can only upload up to ${maxFileUploadCount} files at a time. 
      Please reduce the number of files and try again.`, 'Error');
    resetStateAfterError();
  }, [resetStateAfterError]);

  const handleFileUploadRejected = useCallback(() => {
    toastr.error(
      'You can only save files in one of these formats in Docyt: pdf, image, Word, Excel and PowerPoint.',
      'Something went wrong',
    );
    resetStateAfterError();
  }, [resetStateAfterError]);

  const onUploadFiles = useCallback(async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      handleFileUploadError();
      return;
    }

    const hasInvalidFormat = acceptedFiles.some((file) => !isValidFileFormat(file));
    if (hasInvalidFormat) {
      handleFileUploadRejected();
      return;
    }

    setFileStatuses(acceptedFiles.map((file: File) => ({
      name:         file.name,
      status:       'uploading',
      errorMessage: '',
    })));

    const onStepProcessed = (response: IDocument, params: { file: File, metadata: any }) => {
      setFileStatuses((prevStatuses) => prevStatuses.map((f) => (f.name === params.file.name
        ? { ...f, status: 'success' }
        : f)));
      if (isStoppedUploadRef.current) {
        deleteDocument.mutateAsync({
          id: response.id,
        });
        return;
      }
      setDocumentUploadCount((prev) => prev + 1);
      if (response.serviceDocumentId) {
        getAccountsPayableServiceDocument(response.serviceDocumentId).then((data) => {
          onSingleDocumentUpload(data);
        });
      }
    };

    const onStepFailed = (error: Error, params: { file: File, metadata: any }) => {
      setFileStatuses((prevStatuses) => prevStatuses.map((f) => (f.name === params.file.name
        ? { ...f, status: 'error', errorMessage: error.message }
        : f)));
    };

    const bulkUpload = makeBulkRequest(async (params: { file: File, metadata: any }) => {
      return uploadDocument.mutateAsync(
        {
          documentParams: {
            document: {
              originalFileName:   params.file.name,
              fileContentType:    params.file.type,
              storageSize:        params.file.size,
              standardDocumentId: configData.account_payable_invoice_id,
            },
            businessId,
            inboxDocument: false,
          },
          file: params.file,
        },
      );
    });

    await acceptedFiles.reduce(async (promise: Promise<void>, file: File) => {
      await promise;
      if (isStoppedUploadRef.current) {
        return Promise.resolve(); // Exit the chain
      }
      await bulkUpload({
        params: [{ file, metadata: { type: file.type } }],
        onStepProcessed,
        onStepFailed,
      });

      return Promise.resolve();
    }, Promise.resolve());

    onUploadDone();
  }, [businessId,
    setDocumentUploadCount,
    onSingleDocumentUpload,
    onUploadDone,
    deleteDocument,
    handleFileUploadError,
    handleFileUploadRejected,
    uploadDocument,
  ]);

  const onStop = useCallback(() => {
    isStoppedUploadRef.current = true;
  }, []);

  return {
    fileStatuses,
    onUploadFiles,
    onStop,
  };
};
