import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getSearchTaskUsers } from '@src/requests/task_service_documents';
import { TID } from '@src/types/common';

import { VendorAvatar } from '@src/components/ui/avatars';
import { IFilterFieldUIProps } from '@src/components/ui/filter/utils';
import {
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';

import { ASSIGNEE_USERS_PER_PAGE } from './helper/constants';
import TaskAssigneeSelect from './task_assignee_select';
import { ITaskIconOption, ITaskUserOptionItem } from '../types';

interface IAssigneeSelectFilterFieldProps extends
  Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange'>, IFilterFieldUIProps
{
  businessId: TID;
  toggleElement?: string | React.ReactNode;
  className?: string;
  serviceProviderBusinessId?: string;
  hasBorder?: boolean;
  selectedUser?: ITaskUserOptionItem;
  hideSelectedOption?: boolean;
  isDisabled?: boolean;
  onChange?:(newValue: ITaskIconOption | TIconOption | null | undefined)=>void;
  isMultiSelect?: boolean;
  headerText?: string;
  getSelectedOptions?:(options : ITaskIconOption[])=>void;
  getDropdownState?:(val:boolean)=>void;
  dafaultSelectedOptionsIds?:string[];
}

const AssigneeField = ({
  businessId,
  toggleElement,
  hasBorder,
  className = '',
  selectedUser,
  hideSelectedOption = false,
  serviceProviderBusinessId,
  onChange,
  isMultiSelect = false,
  getSelectedOptions,
  dafaultSelectedOptionsIds,
  headerText,
  getDropdownState,
  isDisabled,
}: IAssigneeSelectFilterFieldProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allOptions, setAllOptions] = useState<ITaskIconOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<ITaskIconOption[]>([]);
  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
  useCallback(async (query, options, { page }) => {
    if (!businessId) {
      return {
        hasMore:    false,
        options:    [],
        additional: { page },
      };
    }

    setIsLoading(true);

    try {
      const data = await getSearchTaskUsers({
        businessId,
        filter:  { name: query },
        page,
        perPage: ASSIGNEE_USERS_PER_PAGE,
        ...(serviceProviderBusinessId && { serviceProviderBusinessId }),
      });

      const hasMore = data.collection?.length > 0;
      const newOptions = data.collection
        .filter((item) => (!hideSelectedOption || String(item.id) !== selectedUser?.value))
        .map((vendor) => ({
          label:  vendor?.parsedFullname,
          value:  String(vendor.id),
          icon:   <VendorAvatar size="100%" vendor={ { ...vendor, imageUrl: vendor?.avatar?.avatarUrl } } />,
          helper: vendor?.title,
        }));
      setAllOptions([...allOptions, ...newOptions] as TIconOption[]);
      return {
        hasMore,
        options: page === 1 && isMultiSelect && !query
          ? [{ label: 'Select All', value: 'select-all' }, ...newOptions] as TIconOption[]
          : newOptions as TIconOption[],
        additional: {
          page: page + 1,
        },
      };
    } catch {
      return {
        hasMore:    false,
        options:    [],
        additional: { page },
      };
    } finally {
      setIsLoading(false);
    }
  }, [allOptions, businessId, serviceProviderBusinessId, hideSelectedOption, isMultiSelect, selectedUser?.value]);

  const onSingleChecked = useCallback((checked:boolean, option:ITaskIconOption) => {
    const isExist = selectedOptions.find((item) => item.value === option.value);
    if (checked && option && !isExist) {
      setSelectedOptions([...selectedOptions, option]);
      if (getSelectedOptions) {
        getSelectedOptions([...selectedOptions, option]);
      }
    } else {
      const filteredList = selectedOptions.filter((item) => item.value !== option.value);
      setSelectedOptions(filteredList);
      if (getSelectedOptions) {
        getSelectedOptions(filteredList);
      }
    }
  }, [getSelectedOptions, selectedOptions]);
  const onSelectAllChecked = useCallback((checked:boolean) => {
    if (checked) {
      setSelectedOptions(allOptions);
      if (getSelectedOptions) {
        getSelectedOptions(allOptions);
      }
    } else {
      setSelectedOptions([]);
      if (getSelectedOptions) {
        getSelectedOptions([]);
      }
    }
  }, [allOptions, getSelectedOptions]);

  useEffect(() => {
    if (dafaultSelectedOptionsIds?.length) {
      const defaultSelectedOptions = allOptions.filter((item) => dafaultSelectedOptionsIds.includes(item.value));
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [allOptions, dafaultSelectedOptionsIds]);

  const selectedItem = useMemo(() => {
    if (!selectedUser?.value) return undefined;

    return {
      icon: <VendorAvatar
        size="100%"
        vendor={ { ...selectedUser,
          imageUrl: String(selectedUser.icon) } }
      />,
      label:  selectedUser?.label,
      helper: selectedUser?.helper,
      value:  selectedUser?.value,
    };
  }, [selectedUser]);

  return (
    <TaskAssigneeSelect
      allOptions={ allOptions }
      className={ className }
      disabled={ isDisabled }
      getDropdownState={ getDropdownState }
      handleSource={ handleSource }
      hasBorder={ hasBorder }
      headerText={ headerText }
      isLoading={ isLoading }
      isMulti={ isMultiSelect }
      selectedItem={ selectedItem }
      selectedOptions={ selectedOptions }
      toggleElement={ toggleElement }
      onChange={ onChange }
      onSelectAllChecked={ onSelectAllChecked }
      onSingleChecked={ onSingleChecked }
    />
  );
};

export default React.memo(AssigneeField);
