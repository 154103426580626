import { TID } from '@src/types/common';
import { IBusinessTeamMember, IMemberInfo, IClientBusiness } from '@src/types/team_management/team_management';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
} from '../helpers';

interface IGetAllMembersParams {
  businessId: number;
  page?: number;
  filter?: object;
}

interface IGetAllMembersResponseInfite {
  collection: IBusinessTeamMember[];
  meta: {
    totalCount: number;
  };
}

interface IGetAllMembersResponse {
  members: IBusinessTeamMember[];
  meta: {
    totalCount: number;
  };
}

interface IRemoveMemberResponse {
  success: boolean;
}

interface IRemoveMemberParams {
  id: TID;
}

interface IGetMemeberInfoParams {
  id: TID;
}

interface IUpdateBusinessRoleParams {
  id: TID;
  businessId: TID;
  isAdmin: boolean;
}

interface IUpdateBusinessRoleResponse {
  success: boolean;
}

interface IGetAllClientsParams {
  id: TID;
  page?: number;
  filter?: object;
}

interface IClientAssignment {
  serviceProviderBusinessId: TID;
  clientIds: TID[];
}
interface IManageClientAssignmentsParams {
  userId: number;
  addedClients: IClientAssignment[];
  removedClients: IClientAssignment[];
}

interface IManageClientAssignmentsResponse {
  success: boolean;
}

interface IGetAllClientsResponse {
  businesses: IClientBusiness[];
  availableBusinesses: IClientBusiness[];
}

const getAllMemebers = (params: IGetAllMembersParams): Promise<IGetAllMembersResponseInfite> => {
  return apiGet('/api/v1/team_management/all_members', underscoreKeys(params))
    .then((data) => {
      const response = camelizeKeys(data) as IGetAllMembersResponse;
      return {
        collection: response.members,
        meta:       {
          totalCount: response.meta.totalCount,
        },
      };
    });
};

const removeMember = (params: IRemoveMemberParams): Promise<IRemoveMemberResponse> => {
  return apiDelete('/api/v1/team_management/remove_member', underscoreKeys(params))
    .then((data) => {
      return camelizeKeys(data) as IRemoveMemberResponse;
    });
};

const getMemeberInfo = (params: IGetMemeberInfoParams): Promise<IMemberInfo> => {
  return apiGet('/api/v1/team_management/member_info', underscoreKeys(params))
    .then((data) => {
      return camelizeKeys(data) as IMemberInfo;
    });
};

const updateBusinessRole = (params: IUpdateBusinessRoleParams): Promise<IUpdateBusinessRoleResponse> => {
  return apiPut('/api/v1/team_management/update_business_role', underscoreKeys(params))
    .then((data) => {
      return camelizeKeys(data) as IUpdateBusinessRoleResponse;
    });
};

const getAllClients = (params: IGetAllClientsParams): Promise<IGetAllClientsResponse> => {
  return apiGet('/api/v1/team_management/all_clients', underscoreKeys(params))
    .then((data) => {
      return camelizeKeys(data) as IGetAllClientsResponse;
    });
};

const manageClientAssignments = (params: IManageClientAssignmentsParams): Promise<IManageClientAssignmentsResponse> => {
  return apiPost('/api/v1/team_management/manage_client_assignments', underscoreKeys(params))
    .then((data) => {
      return camelizeKeys(data) as IManageClientAssignmentsResponse;
    });
};

export {
  IGetAllMembersParams,
  IGetAllMembersResponseInfite,
  getAllMemebers,
  IRemoveMemberParams,
  IRemoveMemberResponse,
  removeMember,
  getMemeberInfo,
  IGetMemeberInfoParams,
  IMemberInfo,
  IUpdateBusinessRoleParams,
  IUpdateBusinessRoleResponse,
  updateBusinessRole,
  IGetAllClientsParams,
  IGetAllClientsResponse,
  IClientBusiness,
  getAllClients,
  IManageClientAssignmentsParams,
  IManageClientAssignmentsResponse,
  manageClientAssignments,
};
