import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import toastr from '@lib/toastr';
import { useGetUsersBusinesses } from '@src/hooks/queries/businesses';
import { useGetAccessibleManagementGroups } from '@src/hooks/queries/management_groups';
import { clientBusinessSettingsPath } from '@src/routes';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Dropdown from '@src/components/ui_v2/dropdown';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';
import { navigateBusinessByIntent } from '@src/components/utils/business_navigation';
import { CaretIcon } from '@src/components/utils/fa_icons';
import { GridViewIcon, StoreFront } from '@src/components/utils/icomoon';

import DropdownSection from './components/dropdown_section';
import MenuItem from './components/menu_item';
import portfolioOptions, { prepareManagementGroupValue, prepareBusinessValue } from './dropdown_helper';
import { TUnifiedDropdownOption } from './types';

import styles from './styles.module.scss';

// Extract BusinessSection as a separate component
const BusinessSection = ({ businesses, searchValue, setSearchValue, onSelect, isAccountantView }:{
  businesses: TUnifiedDropdownOption[],
  searchValue: string,
  setSearchValue: (value: string) => void,
  onSelect: (option: TUnifiedDropdownOption) => void,
  isAccountantView: boolean,
}) => {
  return (
    <>
      <div className={ styles['search-container'] }>
        <SearchInput
          placeholder={ isAccountantView ? 'Search your clients' : 'Search your businesses' }
          value={ searchValue }
          onChange={ setSearchValue }
        />
      </div>
      <div className={ styles['business-items'] }>
        {businesses.map((business) => (
          <div
            key={ business.value }
            className={ styles['business-item'] }
            role="button"
            tabIndex={ 0 }
            onClick={ () => onSelect(business) }
          >
            {business.label}
          </div>
        ))}
      </div>
    </>
  );
};

interface IUnifiedHeaderDropdownProps {
  openCreateManagementGroupModal: () => void,
}
const UnifiedHeaderDropdown = ({ openCreateManagementGroupModal }: IUnifiedHeaderDropdownProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [activePath, setActivePath] = useState<string[]>(['businesses']);
  const [selectedItem, setSelectedItem] = useState<TUnifiedDropdownOption | null>(null);
  const location = useLocation();
  // Determine if we're in accountant view
  const isAccountantView = window.Docyt.currentAdvisor.isBookgptAccountantUser();
  
  // Fetch businesses and management groups
  const businessesQuery = useGetUsersBusinesses({
    view:         isAccountantView ? 'accountant' : 'business',
    businessName: searchValue,
    pageSize:     100,
    column:       'name',
  }, {
    // TODO: remove this once globally stale time is implemented
    keepPreviousData: true,
    staleTime:        5 * 60 * 1000,
  });
  const managementGroupsQuery = useGetAccessibleManagementGroups({
    page: 1,
  }, {
    enabled:          activePath.includes('management-groups'),
    // TODO: remove this once globally stale time is implemented
    keepPreviousData: true,
    staleTime:        5 * 60 * 1000,
  });

  const businesses = useMemo(
    () => businessesQuery.data?.collection || [],
    [businessesQuery.data],
  );
  const managementGroups = useMemo(
    () => managementGroupsQuery.data?.managementGroups || [],
    [managementGroupsQuery.data],
  );

  // Convert businesses to dropdown options
  const businessOptions: TUnifiedDropdownOption[] = useMemo(() => {
    if (!searchValue) {
      return businesses.map((business) => ({
        label:        business.displayName,
        value:        prepareBusinessValue(business.id),
        id:           business.id,
        displayLabel: business.displayName,
        icon:         null,
        type:         'business' as const,
      }));
    }

    return businesses
      .filter((business) => business.displayName.toLowerCase().includes(searchValue.toLowerCase()))
      .map((business) => ({
        label:        business.displayName,
        value:        prepareBusinessValue(business.id),
        id:           business.id,
        displayLabel: business.displayName,
        icon:         null,
        type:         'business' as const,
      }));
  }, [businesses, searchValue]);

  const portfolioOptionsList = useMemo(() => portfolioOptions(managementGroups), [managementGroups]);

  // Parse current URL to determine selected item
  useEffect(() => {
    const currentPath = location.pathname;

    // Check if we're on a business page
    const businessMatch = currentPath.match(/\/businesses\/(\d+)(?:\/clients\/(\d+))?/);
    if (businessMatch && businesses.length > 0) {
      // If client ID exists in the URL, use that instead of business ID
      const clientId = businessMatch[2] !== undefined ? parseInt(businessMatch[2], 10) : null;
      const businessId = parseInt(businessMatch[1], 10);
      const idToUse = clientId || businessId;
      const matchedBusiness = businesses.find((business) => business.id === idToUse);

      if (matchedBusiness) {
        setSelectedItem({
          label:        matchedBusiness.displayName,
          value:        prepareBusinessValue(matchedBusiness.id),
          id:           matchedBusiness.id,
          displayLabel: matchedBusiness.displayName,
          icon:         null,
          type:         'business' as const,
        });
        return;
      }
    }

    // Check if we're on a management group page
    const mgMatch = currentPath.match(/\/management_groups\/(\d+)/);
    if (mgMatch && managementGroups.length > 0) {
      const mgId = parseInt(mgMatch[1], 10);
      const matchedGroup = managementGroups.find((mg) => mg.id === mgId);

      if (matchedGroup) {
        setSelectedItem({
          label:        matchedGroup.name,
          value:        prepareManagementGroupValue(matchedGroup.id),
          id:           matchedGroup.id,
          displayLabel: matchedGroup.name,
          icon:         null,
          type:         'navigate' as const,
        });
      }
    }

    // If no match or data not loaded yet, keep null
  }, [businesses, managementGroups, location]);

  const handleSelect = useCallback((option: TUnifiedDropdownOption) => {
    // Navigate based on type
    if (option.type === 'business') {
      const newBusinessId = option.id;
      const newBusiness = businesses?.find((business) => business.id === Number(newBusinessId));
      const currentBusiness = businesses?.find((business) => business.id === Number(selectedItem?.id));

      // Check if we're in a client (accountant) view or a regular business view
      const isClientView = isAccountantView;

      if (isClientView) {
        // Add permission check for client view
        if (newBusiness && !newBusiness.hasBusinessAccess) {
          toastr.error('You do not have access to this module in this business. Give access to yourself.', 'Error');
          backboneNavigateToUrl(clientBusinessSettingsPath(
            newBusiness.id,
            window.Docyt.currentAdvisor.defaultAccountingFirmId(),
          ));
          return;
        }

        // Client view navigation (similar to client_view.tsx)
        const accountingFirmId = window.Docyt.currentAdvisor.defaultAccountingFirmId();
        navigateBusinessByIntent(
          newBusiness?.businessIntent,
          accountingFirmId, // businessId parameter in client view
          String(newBusinessId), // newClientBusinessId parameter in client view
          true,
          currentBusiness,
          newBusiness,
        );
      } else {
        // Business view navigation (similar to business_view.tsx)
        navigateBusinessByIntent(
          newBusiness?.businessIntent,
          String(newBusinessId), // newBusinessId parameter in business view
          null, // null as the third parameter in business view
          true,
          currentBusiness,
          newBusiness,
        );
      }

      // Once navigation request is made, update the selected item
      setSelectedItem(option);
    } else if (option.type === 'navigate' && option.value.startsWith('management-group-financial-connection')) {
      backboneNavigateToUrl(`/management_groups/${option.id}/financial_connections`);
      setSelectedItem(option);
    } else if (option.type === 'action-add' && option.value === 'add-management-group') {
      openCreateManagementGroupModal();
    } else if (option.type === 'navigate' && option.value.startsWith('all-businesses')) {
      backboneNavigateToUrl('/');
      setSelectedItem(null);
    }
  }, [openCreateManagementGroupModal, businesses, selectedItem, isAccountantView]);

  // Custom dropdown rendering with sections and hover menus
  const customDropdownContent = useCallback(() => {
    return (
      <Dropdown.Provider className={ styles['unified-dropdown-container'] }>
        <Dropdown.ToggleButton className={ styles['unified-dropdown-toggle'] }>
          <div className={ styles['dropdown-button-container'] }>
            <div className={ styles['dropdown-button-label'] }>
              <StoreFront fontSize={ 20 } />
              {selectedItem 
                ? selectedItem.displayLabel 
                : isAccountantView ? 'All Clients' : 'All Businesses'}
            </div>
            <span className={ styles['dropdown-arrow'] }>
              <CaretIcon variant="down" />
            </span>
          </div>
        </Dropdown.ToggleButton>
        <Dropdown.Menu
          className={ styles['unified-dropdown-menu'] }
          onLoad={ () => setActivePath(['businesses']) }
          onMouseLeave={ () => setActivePath([]) }
        >
          <DropdownSection title="">

            <MenuItem
              activePath={ activePath }
              item={ {
                label:        isAccountantView ? 'All Clients' : 'All Businesses',
                value:        'all-businesses',
                type:         'navigate' as const,
                displayLabel: '',
                icon:         <GridViewIcon fontSize={ 20 } />,
              } }
              setActivePath={ setActivePath }
              onSelect={ handleSelect }
            />
          </DropdownSection>


          <DropdownSection title="PORTFOLIOS">
            {portfolioOptionsList.map((option) => (
              <MenuItem
                key={ option.value }
                activePath={ activePath }
                item={ option }
                setActivePath={ setActivePath }
                onSelect={ handleSelect }
              />
            ))}
          </DropdownSection>
          <DropdownSection title={ isAccountantView ? 'CLIENTS' : 'BUSINESSES' }>
            <BusinessSection
              businesses={ businessOptions }
              searchValue={ searchValue }
              setSearchValue={ setSearchValue }
              onSelect={ handleSelect }
              isAccountantView={isAccountantView}
            />
          </DropdownSection>
        </Dropdown.Menu>
      </Dropdown.Provider>
    );
  }, [
    portfolioOptionsList,
    activePath,
    setActivePath,
    searchValue,
    businessOptions,
    handleSelect,
    selectedItem,
    isAccountantView,
  ]);

  return customDropdownContent();
};

export default UnifiedHeaderDropdown;
