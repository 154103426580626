/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { toInteger } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetIntegration, useSetIntegrationPaused } from '@src/hooks/queries/integrations/integrations';
import { useGetRevenueReportTypeDetail } from '@src/hooks/queries/revenue_report_types';
import { useGetRevenueServiceByBusinessId } from '@src/hooks/queries/revenue_services';
import { revenueServicePathRevenueCapturePath } from '@src/routes';
import { IRevenueCategoriesType } from '@src/types/revenue_report_types';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { Button } from '@src/components/ui_v2/buttons';
import Dropdown from '@src/components/ui_v2/dropdown';
import FullPageModal from '@src/components/ui_v2/full_page_modal';
import { DownArrowIcon } from '@src/components/utils/icomoon';

import EditableTable from './editable_table/editable';
import { useImportMappingModal } from './import_mapping/import_mapping_modal';

import styles from './styles.module.scss';

const ReportTypes = () => {
  const navigate = useNavigate();
  const revenueRef = useRef<{ handleSubmit:() => Promise<void> } | null>(null);
  const taxRef = useRef<{ handleSubmit:() => Promise<void> } | null>(null);
  const otherLedgerRef = useRef<{ handleSubmit:() => Promise<void> } | null>(null);
  const paymentProcessorsRef = useRef<{ handleSubmit:() => Promise<void> } | null>(null);
  const expenseAdjustmentsRef = useRef<{ handleSubmit:() => Promise<void> } | null>(null);
  const [mappingErrors, setMappingErrors] = useState<string[]>([]);
  const [mappingNoData, setMappingNoData] = useState<boolean[]>([]);
  const [flagImport, setFlagImport] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const setIntegrationIdRef = useRef(false);

  const importMappingModal = useImportMappingModal();
  const business = useBusinessContext();
  const service = useGetRevenueServiceByBusinessId({ businessId: business.id });

  const { businessId, type_id } = useParams();
  const [reportTypeId, setReportTypeId] = useState<number>(toInteger(type_id));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {
    data: revenueReportData,
    refetch: refetchRevenueReport,
  } = useGetRevenueReportTypeDetail(reportTypeId);

  const [integrationId, setIntegrationId] = useState<number | null>(null);

  const { data: integration } = useGetIntegration(
    {
      integrationId,
      businessId: business.id,
    },
    { enabled: integrationId !== null },
  );

  const { mutate: setAsActiveMutate } = useSetIntegrationPaused();

  const buttonText = integration?.isEnabled ? 'Done' : 'Activate';

  useEffect(() => {
    if (service?.isLoading === false) {
      const selectedRevenueSystem =
    service?.data?.revenueService?.revenueSystems?.find((o) => o.revenueReportTypeId === Number(type_id));

      setSelectedId(selectedRevenueSystem?.id ?? null);
    }
  }, [service?.data?.revenueService?.revenueSystems, service?.isLoading, type_id]);

  const handleCancelClick = useCallback(() => {
    if (selectedId) {
      navigate(
        revenueServicePathRevenueCapturePath(
          business.id,
          selectedId,
        ),
      );
    }
  }, [business.id, navigate, selectedId]);

  const handleProceedClick = async () => {
    try {
      setMappingErrors([]);
      setMappingNoData([]);
      await revenueRef.current?.handleSubmit?.();
      await taxRef.current?.handleSubmit?.();
      await otherLedgerRef.current?.handleSubmit?.();
      await paymentProcessorsRef.current?.handleSubmit?.();
      await expenseAdjustmentsRef.current?.handleSubmit?.();
    } catch (error: any) {
      const errorMsg = error?.message || 'An error occurred while processing your request.';
      toastr.error(
        errorMsg,
        'Error',
      );
      setMappingErrors((prevErrors) => [...prevErrors, errorMsg]);
    }
  };

  useEffect(() => {
    if (!setIntegrationIdRef.current) {
      if (revenueReportData?.revenueReportType?.integrationId) {
        setIntegrationId(revenueReportData.revenueReportType.integrationId);
        setIntegrationIdRef.current = true;
      }
    }
  }, [revenueReportData]);

  useEffect(() => {
    if (mappingErrors.length >= 5 && mappingErrors.every((res) => res === 'Success')) {
      if (mappingNoData.length >= 5 && mappingNoData.every((res) => res === true)) {
        toastr.error(
          'Please add at least one item to the revenue mapping.',
          'Update Unsuccessful',
        );
      } else {
        if (integration?.id && !integration?.isEnabled) {
          setAsActiveMutate(
            { integrationId: integration.id, isPaused: false },
            {
              onSuccess: () => {
                toastr.success(
                  'Docyt will start pulling your revenue reports shortly',
                  'Revenue System Activated.',
                );
              },
            },
          );
        }
        if (selectedId) {
          navigate(
            revenueServicePathRevenueCapturePath(
              business.id,
              selectedId,
            ),
          );
        }
        toastr.success(
          'Items/Services have been successfully updated.',
          'Update Successful',
        );
      }
    }
  }, [
    business.id,
    businessId,
    integration?.id,
    integration?.isEnabled,
    mappingErrors,
    mappingNoData,
    navigate,
    selectedId,
    setAsActiveMutate,
    type_id,
  ]);

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['actions-dropdown-toggle-button'] }>
      <Button variant="primary">
        Import
        <DownArrowIcon fontSize={ 14 } />
      </Button>
    </Dropdown.ToggleButton>
  );

  const handleImportMapping = () => {
    importMappingModal.open();
  };

  const renderEditableTable = (
    ref: React.Ref<unknown> | undefined,
    data: IRevenueCategoriesType[] | undefined,
    title: string,
  ) => (
    <EditableTable
      ref={ ref }
      data={ data ?? [] }
      flagImport={ flagImport }
      refetchRevenueReport={ refetchRevenueReport }
      reportTypeId={ toInteger(type_id) }
      setMappingErrors={ (val: string) => {
        setMappingErrors((prevErrors) => [...prevErrors, val]);
      } }
      setMappingNoData={ (val: boolean) => {
        setMappingNoData((prevErrors) => [...prevErrors, val]);
      } }
      title={ title }
    />
  );

  return (
    <>
      <importMappingModal.Component
        { ...importMappingModal.props }
        setReportTypeId={ (id) => {
          setReportTypeId(id);
          setFlagImport(true);
        } }
      />
      <FullPageModal>
        <FullPageModal.Header
          divider="horizontal"
          title="Mapping"
          onCancel={ handleCancelClick }
        />
        <FullPageModal.Body>
          <div className={ styles['title-body'] }>
            <p className={ styles['title-body-text'] }>
              Please input the line items you want to import from the downloaded/uploaded revenue reports
              and map them with the respective Chart of Accounts under which you want to book those line items.
            </p>
            <ActionsDropdown
              className={ styles['actions-dropdown-content'] }
              toggleButton={ toggleButton }
            >
              <ActionsDropdown.Action
                title="Import form Another Business"
                onSelect={ handleImportMapping }
              />
            </ActionsDropdown>
          </div>
          <br />
          <div>
            {renderEditableTable(
              revenueRef,
              revenueReportData?.revenueReportType?.revenueCategories,
              'Revenue Categories',
            )}
            {renderEditableTable(
              taxRef,
              revenueReportData?.revenueReportType?.taxCategories,
              'Tax Categories',
            )}
            {renderEditableTable(
              otherLedgerRef,
              revenueReportData?.revenueReportType?.otherLedgerCategories,
              'Other Ledger Categories',
            )}
            {renderEditableTable(
              paymentProcessorsRef,
              revenueReportData?.revenueReportType?.paymentProcessors,
              'Payment Processors',
            )}
            {renderEditableTable(
              expenseAdjustmentsRef,
              revenueReportData?.revenueReportType?.expenseAdjustments,
              'Expense Adjustments',
            )}
          </div>
        </FullPageModal.Body>

        <div className={ styles.footerWrapper }>
          <FullPageModal.Footer
            isProceedDisabled={ selectedId === null }
            proceedTitle={ buttonText }
            onCancel={ handleCancelClick }
            onProceed={ handleProceedClick }
          >
            <FullPageModal.FooterFiller>
              <span className={ styles['footer-mapping-modal'] } />
            </FullPageModal.FooterFiller>
          </FullPageModal.Footer>
        </div>
      </FullPageModal>
    </>
  );
};

export default ReportTypes;
