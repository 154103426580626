import React, { useCallback } from 'react';

import classNames from 'classnames';

import toastr from '@lib/toastr';
import { useAssignTaskServiceDocument } from '@src/hooks/queries/task_service_documents';
import { ITaskServiceDocument } from '@src/types/task_service_documents';
import { getDateGap } from '@src/utils/date_helpers';

import Assignee from '@src/components/task_management/components/assignee';
import NoDataFound from '@src/components/task_management/components/no_data_found';
import TaskDateField from '@src/components/task_management/components/task_date_field';
import TaskOpenSince from '@src/components/task_management/components/task_open_since';
import { UserAvatar } from '@src/components/ui/avatars';
import { TIconOption } from '@src/components/ui/form';
import Spinner from '@src/components/ui/spinner';
import Table from '@src/components/ui_v2/table';

import { ITaskIconOption } from '../../types';

import styles from '../styles.module.scss';

interface IDashboardTableBodyProps {
    records:ITaskServiceDocument[];
    onRowClick:(rowData : ITaskServiceDocument)=>void;
    isLoading:boolean;
}
const TableBody = ({ records, onRowClick, isLoading = false }:IDashboardTableBodyProps) => {
  const classes = classNames(styles['task-default-row'], styles['red-row']);
  const assignTask = useAssignTaskServiceDocument();
  const handleAssigneeChange = useCallback((taskId:string, user?:ITaskIconOption | TIconOption | null) => {
    if (user?.value && taskId) {
      assignTask.mutate(
        { formData: { userId: user?.value },
          taskId },
        {
          onSuccess: () => {
            toastr.success(
              'Assign Task Successfully',
              'Assign Task',
            );
          },
          onError: ({ response }) => {
            toastr.error(
              response?.data?.error || 'Some error occurred',
              'Assign Task',
            );
          },
        },
      );
    }
  }, [assignTask]);

  if (records.length === 0 && !isLoading) {
    return (
      <NoDataFound noDataMessage="No Tasks Found" />
    );
  }

  return (
    <Table.Body>
      {!isLoading ? records?.map((columnData:ITaskServiceDocument) => (
        <Table.Row
          key={ columnData.taskId }
          className={ getDateGap({ date: columnData.createdAt }).isGreaterOrEqualTwoDays
            ? classes : styles['task-default-row'] }
        >
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              #
              {columnData.taskId}
            </div>
          </Table.Cell>
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              {columnData.title}
            </div>
          </Table.Cell>
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              {columnData.businessName}
            </div>
          </Table.Cell>
          <Table.Cell>
            <TaskDateField disabled value={ columnData.createdAt } />
          </Table.Cell>
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              <TaskOpenSince date={ columnData?.createdAt } />
            </div>
          </Table.Cell>
          <Table.IconCell>
            <Assignee
              businessId={ columnData?.businessId }
              headerText="Assign To"
              isDisabled={ (!columnData?.canEdit || !columnData?.serviceProviderBusinessId) }
              serviceProviderBusinessId={ columnData.serviceProviderBusinessId }
              toggleElement={ (
                <UserAvatar
                  className={ classNames({
                    [styles['disable-icon']]: !columnData?.canEdit || !columnData?.serviceProviderBusinessId,
                  }) }
                  inColor="grey-800"
                />
             ) }
              onChange={ (newValue) => {
                handleAssigneeChange(columnData.taskId, newValue);
              } }
            />
          </Table.IconCell>
        </Table.Row>
      )) : <Spinner />}
    </Table.Body>
  );
};
export default TableBody;
