import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateRevenueSystem } from '@src/hooks/queries/revenue_systems';
import { ICreateRevenueSystemResponse } from '@src/requests/revenue_systems';
import { revenueServicePathRevenueCapturePath } from '@src/routes';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import Form from './form';
import { useManualRevenueSystemSuccessModal } from './manual_revenue_system_success_modal';
import { IManualRevenueSystemValues } from './schema';
import { useConnectRevenueSystemModal } from '../connect_system/connect_system_modal';

interface IManualRevenueSystemModelProps extends IUseModalProps {
  valueForm?: IManualRevenueSystemValues,
  revenueService: IRevenueService,
  source: string,
}

const ManualRevenueSystemModel = ({
  isOpen,
  onDone,
  onCancel,
  valueForm,
  revenueService,
  ...props
}: IManualRevenueSystemModelProps) => {
  const business = useBusinessContext();
  const [isFormValid, setIsFormValid] = useState(false);
  const [manualRevenueSystemValues, setManualRevenueSystemValues] = useState<IManualRevenueSystemValues | undefined>();
  const [revenueSystemResponse, setRevenueSystemResponse] = useState<ICreateRevenueSystemResponse | undefined>();
  const [revenueName, setRevenueName] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [revenueSystem, setRevenueSystem] = useState<IRevenueSystem | undefined>();

  const createRevenueSystem = useCreateRevenueSystem();
  const manualRevenueSystemSuccessModal = useManualRevenueSystemSuccessModal();

  const { mutate } = createRevenueSystem;
  const navigate = useNavigate();

  const handleError = useCallback((response) => {
    toastr.error(response?.response?.data?.errors[0], 'Error');
  }, []);

  const handleSubmit = useCallback(
    (data: IManualRevenueSystemValues) => {
      setIsLoading(!isLoading);
      mutate(
        {
          revenueServiceId: revenueService.id,
          data,
        },
        {
          onSuccess: (res) => {
            setIsLoading(!isLoading);
            setRevenueName(data.name);
            setRevenueSystem(res.revenueSystem);
            setManualRevenueSystemValues(data);
            manualRevenueSystemSuccessModal.open();
            setRevenueSystemResponse(res);
            setManualRevenueSystemValues(data);
            onDone();
          },
          onError: handleError,
        },

      );
    },
    [isLoading, mutate, revenueService.id, handleError, onDone, manualRevenueSystemSuccessModal],
  );

  const connectSystemModal = useConnectRevenueSystemModal();

  const handleOnCancel = useCallback(() => {
    connectSystemModal.open();
    onCancel();
  }, [connectSystemModal, onCancel]);

  const onDoItLater = useCallback(() => {
    manualRevenueSystemSuccessModal.props?.onCancel();
    if (revenueSystemResponse?.revenueSystem?.id) {
      navigate(
        revenueServicePathRevenueCapturePath(
          business.id,
          revenueSystemResponse?.revenueSystem?.id,
        ),
      );
    }
  }, [business.id, manualRevenueSystemSuccessModal.props, navigate, revenueSystemResponse?.revenueSystem?.id]);

  return (
    <>
      <connectSystemModal.Component
        { ...connectSystemModal.props }
      />
      {manualRevenueSystemValues?.name && (
      <manualRevenueSystemSuccessModal.Component
        revenueName={ manualRevenueSystemValues.name }
        revenueService={ revenueService }
        revenueSystem={ revenueSystem }
        { ...manualRevenueSystemSuccessModal.props }
        onCancel={ onDoItLater }
      />
      )}
      <Modal
        show={ isOpen }
        title="Revenue System - Manual Setup"
        { ...props }
      >
        <>
          <Modal.Body>
            <p>Enter a name and select the starting date for the revenue system.</p>
            <Form
              business={ business }
              formId="edit_linked_businesses_form"
              valueForm={ valueForm }
              onSubmit={ handleSubmit }
              onValidationChange={ setIsFormValid }
            />
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ handleOnCancel }
            >
              Back
            </Button>
            <Button
              disabled={ !isFormValid || isLoading }
              form="edit_linked_businesses_form"
              suffixIcon={ isLoading ? <SpinnerIcon spin fontSize={ 20 } /> : '' }
              type="submit"
              variant="primary"
            >
              Connect
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

const useManualRevenueSystemModel = makeUseModal(ManualRevenueSystemModel);

export {
  useManualRevenueSystemModel,
  ManualRevenueSystemModel as default,
};
