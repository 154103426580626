import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import {
  useGetMatchedDocuments,
  useGetTransactionServiceDocumentsForDocument,
} from '@src/hooks/queries/transaction_service_documents';
import { useGetDocumentNotes } from '@src/requests/all_transactions';
import { IDocumentRelative } from '@src/types/document_relative';
import { IRelatedDocument } from '@src/types/related_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { Button } from '@src/components/ui_v2/buttons';
import PlusIcon from '@src/components/utils/icomoon/plus';

import List from './list';
import Note from './note';

import styles from '../styles.module.scss';

interface IMatchedDocumentDetailsProps {
  document: ITransactionServiceDocument;
}

const transactionTypes = window.configData.transaction_types;

const MatchedDocumentDetails = ({ document }: IMatchedDocumentDetailsProps): JSX.Element => {
  const [isForDocument, setIsForDocument] = useState<boolean>(false);
  const { transactionType, noDocumentRequired, hasDocuments } = document;
  const query = useGetMatchedDocuments(
    { id: document.documentId, withoutStandard: true },
    hasDocuments,
  );
  useGetTransactionServiceDocumentsForDocument({ documentId: document.documentId }, isForDocument);
  const notesQuery = useGetDocumentNotes({ documentId: document.documentId }, noDocumentRequired);

  const classes = classNames(styles['matched-document-header'], 'm-l-25 m-r-25', {
    'm-t-25': transactionType !== transactionTypes.EXPENSE,
  });

  const records = useMemo(() => {
    const relatedDocuments = query.data?.collection || [];
    return relatedDocuments.map((relatedDocument: IRelatedDocument) => {
      return {
        ...relatedDocument.documentRelative,
        relatedDocumentId: relatedDocument.id,
      } as IDocumentRelative;
    });
  }, [query.data]);

  const note = useMemo(() => {
    if (!document.noDocumentRequired) return null;
    if (!notesQuery.data?.collection || notesQuery.data?.collection.length === 0) return null;
    return notesQuery.data?.collection[notesQuery.data.collection.length - 1];
  }, [document.noDocumentRequired, notesQuery.data]);

  const handleTransactionsChanged = useCallback(() => {
    setIsForDocument(true);
    query.refetch();
    setIsForDocument(false);
  }, [query, setIsForDocument]);

  useEffect(() => {
    if (document.documentNote?.note) {
      notesQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.documentNote?.note]);

  useEffect(() => {
    window.Docyt.vent.on('related:documents:changed', handleTransactionsChanged);

    return () => {
      window.Docyt.vent.off('related:documents:changed');
    };
  }, [handleTransactionsChanged]);

  const handleAddDocument = useCallback(() => {
    window.Docyt.vent.trigger('related:documents:add', document);
  }, [document]);

  const renderContent = useCallback(() => {
    if (!noDocumentRequired || hasDocuments) {
      if (!noDocumentRequired) {
        return (
          <>
            <List documents={ records } transactionType={ transactionType } />
            <Button
              className="in-blue-900"
              prefixIcon={ <PlusIcon className="in-blue-900" fontSize={ 16 } variant="o" /> }
              variant="link"
              onClick={ handleAddDocument }
            >
              Add Document
            </Button>
          </>
        );
      }

      return note && <Note document={ document } note={ note } />;
    }

    return note && <Note document={ document } note={ note } />;
  }, [
    noDocumentRequired,
    hasDocuments,
    records,
    transactionType,
    note,
    document,
    handleAddDocument,
  ]);

  return (
    <>
      <div className={ classes }>
        DOCUMENTS
      </div>
      <div className={ styles['matched-document-content'] }>
        { renderContent() }
      </div>
    </>
  );
};

export {
  IMatchedDocumentDetailsProps,
  MatchedDocumentDetails as default,
};
