import React, { useCallback, useEffect } from 'react';

import { uniq, uniqBy } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getSearchFilterAssignees, getSearchFilterBusinesses,
  ISearchTaskUsersParams } from '@src/requests/task_service_documents';
import { TFilterData } from '@src/types/filter';

import { VendorAvatar } from '@src/components/ui/avatars';
import Filter, { useFilterField } from '@src/components/ui_v2/filter';
import {
  TIconOption,
} from '@src/components/ui_v2/inputs';
import { SearchIcon } from '@src/components/utils/icomoon';

import FilterLabel from '../components/filter_label';
import { ASSIGNEE_USERS_PER_PAGE, filterStatusOptions, TASK_TABS } from '../components/helper/constants';
import MultiSelectField from '../components/multi_select_field';
import { ITaskIconOption, StatusKey, TTaskManagementTabs } from '../types';

import styles from './styles.module.scss';

interface ITaskFilterFormValues {
  inputValueUser: string;
  inputValueBusiness: string;
  allUserOptions: ITaskIconOption[];
  allBusinessOptions: ITaskIconOption[];
  isLoading:boolean;
}
interface IAllTasksFilterProps {
  tab:TTaskManagementTabs;
  status:StatusKey[];
}
type TAllOptionsKey = 'allBusinessOptions' | 'allUserOptions'
interface IHandleSourceProps<T> {
  query: string;
  fetchData: (params: T) => Promise<{ collection: any[] }>;
  params: {
    page: number;
    perPage: number;
  };
  allOptionsKey: TAllOptionsKey;
  allOptions: ITaskIconOption[];
}

const AllTasksFilter = ({ tab, status }:IAllTasksFilterProps) => {
  const [userIds, updateUserIds] = useFilterField<TFilterData, { [key: number]: string | string[]}>(
    'userId',
  );
  const [businessIds, updateBusinessIds] = useFilterField<TFilterData, { [key: number]: string }>(
    'businessId',
  );
  const [filterStatus, updateFilterStatus] = useFilterField<TFilterData, string[]>('status');
  const { control, setValue, watch } = useForm<ITaskFilterFormValues>({
    defaultValues: {
      inputValueUser:     '',
      inputValueBusiness: '',
      allUserOptions:     [],
      allBusinessOptions: [],
      isLoading:          false,
    },
  });
  const allOptionsBusiness = watch('allBusinessOptions');
  const allOptionsUsers = watch('allUserOptions');
  useEffect(() => {
    if (!Array.isArray(userIds) && typeof userIds === 'object') {
      const storedUserIds = Object?.values(userIds);
      const uniqueIds = uniq(storedUserIds);
      updateUserIds(uniqueIds);
    }
    if (!Array.isArray(businessIds) && typeof businessIds === 'object') {
      const storedBusinessIds = Object?.values(businessIds);
      const uniqueIds = uniq(storedBusinessIds);
      updateBusinessIds(uniqueIds);
    }
    if (!filterStatus) {
      updateFilterStatus(status);
    } else if (!Array.isArray(filterStatus) && typeof filterStatus === 'object') {
      const storedStatuses = Object?.values(filterStatus);
      updateFilterStatus(storedStatuses as string[]);
    }
  }, [businessIds, filterStatus, updateBusinessIds, updateUserIds, userIds, status, updateFilterStatus]);

  const handleSource =
  useCallback(async ({ query, fetchData, params, allOptionsKey, allOptions }
    :IHandleSourceProps<ISearchTaskUsersParams>) => {
    setValue('isLoading', true);
    const filter = query ? { name: query } : {};
    try {
      const data = await fetchData({
        filter,
        page:    params.page,
        perPage: ASSIGNEE_USERS_PER_PAGE,
      });

      const hasMore = data.collection?.length > 0;
      const newOptions = data.collection
        .map((item:any) => ({
          key:    item?.businessId || item.userId,
          label:  item?.displayName ?? '',
          value:  String(item?.userId ?? item?.businessId),
          icon:   <VendorAvatar size="100%" vendor={ { ...item, imageUrl: item?.icon } } />,
          helper: item?.title,
        }));
      const uniqueOptions = uniqBy([...allOptions, ...newOptions], 'value');
      setValue(allOptionsKey, uniqueOptions as ITaskIconOption[]);
      return {
        hasMore,
        options: params.page === 1 && !query
          ? [{ label: 'Select All', value: 'select-all' }, ...newOptions]
          : newOptions,
        additional: {
          page: params.page + 1,
        },
      };
    } catch {
      return {
        hasMore:    false,
        options:    [],
        additional: { page: params.page },
      };
    } finally {
      setValue('isLoading', false);
    }
  }, [setValue]);

  const handleSourceBusiness: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
  useCallback(async (query, _, { page }) => {
    return handleSource({
      query,
      fetchData:     getSearchFilterBusinesses,
      params:        { page, perPage: ASSIGNEE_USERS_PER_PAGE },
      allOptions:    allOptionsBusiness,
      allOptionsKey: 'allBusinessOptions',
    });
  }, [allOptionsBusiness, handleSource]);

  const handleSourceAssignees: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
  useCallback(async (query, _, { page }) => {
    return handleSource({
      query,
      fetchData:     getSearchFilterAssignees,
      params:        { page, perPage: ASSIGNEE_USERS_PER_PAGE },
      allOptions:    allOptionsUsers,
      allOptionsKey: 'allUserOptions',
    });
  }, [allOptionsUsers, handleSource]);

  const onHandleRemoveBusinessFilter = useCallback(() => {
    updateBusinessIds([]);
    setValue('inputValueBusiness', '');
    setValue('allBusinessOptions', []);
  }, [setValue, updateBusinessIds]);

  const onHandleRemoveUserFilter = useCallback(() => {
    updateUserIds([]);
    setValue('inputValueUser', '');
    setValue('allUserOptions', []);
  }, [setValue, updateUserIds]);

  return (
    <Filter.TableContainer className={ styles['task-table-filter'] }>
      <div className={ styles['filters-container'] }>
        <Filter.TextField
          hideClear
          className={ styles['task-search-container'] }
          label="Task ID"
          name="taskId"
          placeholder="Task ID"
          prefixIcon={ <SearchIcon /> }
          showTooltip={ false }
          width="10%"
        />
        <Controller
          control={ control }
          name="inputValueBusiness"
          render={ ({ field }) => (
            <MultiSelectField
              hideIcon
              isMultiSelect
              allOptions={ watch('allBusinessOptions') }
              defaultSelectedOptionsIds={ businessIds as string[] }
              getDropdownState={ (val) => {
                if (!val) {
                  setValue('inputValueBusiness', '');
                }
              } }
              getSelectedOptions={ (options) => {
                const ids = uniq(options.map((item) => String(item?.value)));
                updateBusinessIds(ids);
              } }
              handleSource={ handleSourceBusiness }
              inputValue={ field.value }
              isLoading={ watch('isLoading') }
              noOptionMessage="No businesses found"
              toggleElement={ (
                <FilterLabel
                  label="All Businesses"
                  selectedItems={ businessIds as string[] }
                  suffixLabel={ Object.keys(businessIds ?? []).length > 1 ? 'businesses' : 'business' }
                  onRemoveClick={ onHandleRemoveBusinessFilter }
                />
              ) }
              onInputChange={ (value, { action }) => {
                if (action === 'input-change') {
                  setValue('inputValueBusiness', value);
                }
              } }
            />
          ) }
        />
        {tab !== TASK_TABS.OPEN && (
        <Controller
          control={ control }
          name="inputValueUser"
          render={ ({ field }) => (
            <MultiSelectField
              isMultiSelect
              allOptions={ watch('allUserOptions') }
              defaultSelectedOptionsIds={ userIds as string[] }
              getDropdownState={ (val) => {
                if (!val) {
                  setValue('inputValueUser', '');
                }
              } }
              getSelectedOptions={ (options) => {
                const ids = uniq(options.map((item) => String(item?.value)));
                updateUserIds(ids);
              } }
              handleSource={ handleSourceAssignees }
              inputValue={ field.value }
              isLoading={ watch('isLoading') }
              toggleElement={ (
                <FilterLabel
                  label="All Assignees"
                  selectedItems={ userIds as string[] }
                  suffixLabel={ Object.keys(userIds ?? {}).length > 1 ? 'assignees' : 'assignee' }
                  onRemoveClick={ onHandleRemoveUserFilter }
                />
                ) }
              onInputChange={ (value, { action }) => {
                if (action === 'input-change') {
                  setValue('inputValueUser', value);
                }
              } }
            />
          ) }
        />
        )}
        {tab === TASK_TABS.ASSIGNED && (
        <Filter.SelectField
          alwaysShowIndicator
          hideClear
          className={ styles['task-search-container'] }
          name="status"
          options={ filterStatusOptions }
          placeholder="All Statuses"
        />
        )}
      </div>
    </Filter.TableContainer>
  );
};
export default React.memo(AllTasksFilter);
