import { IServiceDocumentSplit } from '@src/types/accounts_payable/service_document_split';
import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';

import { apiGet, apiPost } from '../helpers';

interface IGetServiceDocumentSplitsParams {
  documentID: TID,
}

interface IGetServiceDocumentSplitsResponse {
  serviceDocumentSplits: IServiceDocumentSplit[],
}

const getServiceDocumentSplits = (
  params: IGetServiceDocumentSplitsParams,
): Promise<IGetServiceDocumentSplitsResponse> => {
  return apiGet(
    '/api/v1/service_document_splits',
    underscoreKeys({
      ...params,
    }),
  ).then(
    (data) => camelizeKeys(data) as IGetServiceDocumentSplitsResponse,
  );
};

interface ICreateServiceDocumentSplitsParams {
  documentId: TID,
  serviceDocumentSplits: ICategorySplit[],
}

const createServiceDocumentSplits = (
  params: ICreateServiceDocumentSplitsParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/service_document_splits/create_batch',
    underscoreKeys(params),
  );
};

export {
  IGetServiceDocumentSplitsParams,
  IGetServiceDocumentSplitsResponse,
  ICreateServiceDocumentSplitsParams,
  getServiceDocumentSplits,
  createServiceDocumentSplits,
};
