import { TID } from '@src/types/common';
import { IDocumentField } from '@src/types/document_field';
import { IDocument } from '@src/types/documents';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from './helpers';

interface IGetDocumentFieldsParams {
  documentId: TID,
}

interface IGetDocumentFieldsResponse {
  documentFields: IDocumentField[],
}

interface ISaveDocumentFieldValueParams {
  documentFieldValue: {
    documentId: TID,
    id: TID | null,
    inputValue: string,
    localStandardDocumentFieldId: number,
  },
}

interface ISaveDocumentFieldValueResponse {
  documentFieldValue: {
    id: TID,
    value: string,
  },
}

interface IUpdateVendorParams {
  document: {
    id: TID,
    vendorId: TID,
  },
}

interface IUpdateVendorResponse {
  document: IDocument,
}

const getDocumentFields = (
  params: IGetDocumentFieldsParams,
): Promise<IGetDocumentFieldsResponse> => {
  return apiGet(
    '/api/v1/document_fields',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as IGetDocumentFieldsResponse);
};

const saveDocumentFieldValue = (
  params: ISaveDocumentFieldValueParams,
): Promise<ISaveDocumentFieldValueResponse> => {
  return apiPost(
    `/api/web/v1/documents/${params.documentFieldValue.documentId}/document_field_values`,
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as ISaveDocumentFieldValueResponse);
};

const updateVendor = (params: IUpdateVendorParams) => {
  return apiPut(
    `/api/v1/documents/${params.document.id}/update_vendor`,
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as IUpdateVendorResponse);
};

export {
  IGetDocumentFieldsParams,
  IGetDocumentFieldsResponse,
  getDocumentFields,
  ISaveDocumentFieldValueParams,
  ISaveDocumentFieldValueResponse,
  saveDocumentFieldValue,
  IUpdateVendorParams,
  IUpdateVendorResponse,
  updateVendor,
};
