import React from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { unreadTransactionMessagesPath } from '@src/routes';
import { TID } from '@src/types/common';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import {
  FlaggedTransactionsIcon,
  DocumentRequestsIcon,
  TransactionMessagesIcon,
} from '@src/components/utils/icomoon';

interface IOpenItemsSidebarActionProps {
  businessId: TID,
}

const OpenItemsSidebarAction = ({
  businessId,
}: IOpenItemsSidebarActionProps): JSX.Element => {
  const { data: info } = useGetBusinessServicesInfo(businessId);

  const { data: sunsetClientCentralEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
  );

  return (
    <ServiceSidebar>
      {((info?.isAccountingFirmUser && info?.documentRequestServiceRole) || (info?.canAccessTaskAdvisor && info?.documentRequestServiceRole)) && (
        <ServiceSidebar.Item
          badge={ info?.countDocumentRequests }
          href={ `/businesses/${businessId}/open_items/document_requests` }
          icon={ <DocumentRequestsIcon /> }
          isHidden={ !sunsetClientCentralEnabled }
          title="Document Requests"
        />
      )}
      {
        info?.isBusinessMember && info?.hasFlaggedTransactionsAdvisorRole && (
          <ServiceSidebar.Item
            badge={ info?.countOpenedItems }
            href={ `/businesses/${businessId}/open_items/flagged_transactions` }
            icon={ <FlaggedTransactionsIcon /> }
            title="Flagged Transactions"
          />
        )
      }
      {
        info?.isBusinessMember && (
          <ServiceSidebar.Item
            badge={ info?.countUnreadTransactionChatMessages }
            href={ unreadTransactionMessagesPath(businessId) }
            icon={ <TransactionMessagesIcon /> }
            title="Transaction Messages"
          />
        )
      }
    </ServiceSidebar>
  );
};

export default OpenItemsSidebarAction;
