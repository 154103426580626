import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  IGetDocumentFieldsParams,
  IGetDocumentFieldsResponse,
  getDocumentFields,
  ISaveDocumentFieldValueParams,
  ISaveDocumentFieldValueResponse,
  saveDocumentFieldValue,
  updateVendor,
  IUpdateVendorParams,
  IUpdateVendorResponse,
} from '@src/requests/document_field';

const DOCUMENT_FIELDS_QUERY = 'document_fields';
const SAVE_DOCUMENT_FIELD_VALUE = 'save_document_field_value';
const UPDATE_VENDOR = 'update_vendor';

const useGetDocumentFields = (params: IGetDocumentFieldsParams) => {
  return useQuery<IGetDocumentFieldsResponse, Error>(
    [DOCUMENT_FIELDS_QUERY, params],
    () => getDocumentFields(params),
  );
};

const useSaveDocumentFieldValue = () => {
  const queryClient = useQueryClient();

  return useMutation<ISaveDocumentFieldValueResponse, Error, ISaveDocumentFieldValueParams>(
    saveDocumentFieldValue,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SAVE_DOCUMENT_FIELD_VALUE);
      },
    },
  );
};

const useUpdateVendor = () => {
  const queryClient = useQueryClient();

  return useMutation<IUpdateVendorResponse, Error, IUpdateVendorParams>(
    updateVendor,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(UPDATE_VENDOR);
      },
    },
  );
};

export {
  useGetDocumentFields,
  useSaveDocumentFieldValue,
  useUpdateVendor,
};
