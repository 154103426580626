import React from 'react';

import TableSection from '@src/components/ui_v2/table_section/table_section';

import AllTaskFilter from './filter';
import Table from './table';
import { ITasksCollection } from '../hooks';
import { StatusKey, TTaskManagementTabs } from '../types';

import styles from './styles.module.scss';

interface IOpenTasksProps {
  tab: TTaskManagementTabs,
  collection :ITasksCollection;
  status:StatusKey[];
}
const TasksList = ({
  tab,
  collection,
  status,
}: IOpenTasksProps) => {
  return (
    <TableSection className={ styles['task-table-section'] }>
      <AllTaskFilter status={ status } tab={ tab } />
      <Table
        collection={ collection }
        tab={ tab }
      />
    </TableSection>
  );
};
export default TasksList;
