import React, { forwardRef, RefObject, useRef } from 'react';

import isArray from 'lodash/isArray';
import { UseInfiniteQueryResult } from 'react-query';

import { useInfiniteScroll } from '@src/hooks/scroll';

import LocalQueryStatus from '@src/components/utils/local_query_status';

interface IInfiniteScrollTableProps extends React.ComponentPropsWithoutRef<'table'> {
  wrapperClassName?: string,
  infiniteQuery: UseInfiniteQueryResult<any, Error> | UseInfiniteQueryResult<any, Error>[],
}

const InfiniteScrollTable = forwardRef<HTMLDivElement, IInfiniteScrollTableProps>(({
  children,
  infiniteQuery,
  wrapperClassName,
  ...tableProps
}: IInfiniteScrollTableProps, ref) => {
  const localRef = useRef<HTMLDivElement>(null);
  const wrapperRef = ref as RefObject<HTMLDivElement> || localRef;

  useInfiniteScroll({
    elementRef: wrapperRef,
    query:      isArray(infiniteQuery) ? infiniteQuery[0] : infiniteQuery,
  });

  return (
    <div
      ref={ wrapperRef }
      className={ wrapperClassName }
    >
      <table { ...tableProps }>
        { children }
      </table>
      <LocalQueryStatus noSpinner={ !(infiniteQuery as any)?.isFetchingNextPage } query={ infiniteQuery } />
    </div>
  );
});

InfiniteScrollTable.displayName = 'InfiniteScrollTable';

export default React.memo(InfiniteScrollTable);
