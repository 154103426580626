import React, { useState, useCallback } from 'react';

import { useForm } from 'react-hook-form';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';

import { Button } from '@src/components/ui_v2/buttons';
import { CaretIcon } from '@src/components/utils/fa_icons';

import DocumentFields from './document_fields';
import DocumentDetailsTabs from './tabs';

import styles from './styles.module.scss';

interface IDocumentDetailsProps {
  document: IDocument;
  serviceDocument: IAccountsPayableServiceDocument;
}

const DocumentDetails = ({
  document,
  serviceDocument,
}: IDocumentDetailsProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const {
    control,
    register,
    watch,
    setValue,
  } = useForm<IAccountsPayableServiceDocument>({
    defaultValues: serviceDocument,
  });

  const toggleTabs = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  return (
    <div className={ styles['document-details'] }>
      <div className={ styles['document-details-fields'] }>
        <div className={ styles['document-details-header'] }>
          <p className={ styles['document-details-header-title'] }>Invoice Details</p>
          <p className={ styles['document-details-header-subtitle'] }>All fields with * are required</p>
        </div>
        <DocumentFields
          document={ document }
          form={ {
            control,
            register,
            setValue,
            watch,
          } }
          serviceDocument={ serviceDocument }
        />
        <Button
          className={ styles['show-more-info-button'] }
          size="compact"
          suffixIcon={ <CaretIcon variant="down" /> }
          variant="link"
          onClick={ toggleTabs }
        >
          Show more info
        </Button>
      </div>
      <DocumentDetailsTabs
        document={ document }
        isCollapsed={ isCollapsed }
      />
    </div>
  );
};

export default React.memo(DocumentDetails);
